import React, { useCallback, useMemo } from 'react';
import { Badge, Col, Flex, Grid, message, Row, Space, Typography } from 'antd';
import { useTranslation } from 'react-i18next';
import { WordDetail } from '../../pages/WordDetail/type/WordDetailType';
import Text from '../base/Text';
import { useNavigate } from 'react-router-dom';

type TData = WordDetail['meanings'][0] & { title: string };

interface HokkienExampleProps {
  data: TData;
  meaningIndex?: number;
}

const { useBreakpoint } = Grid;

const HokkienExample: React.FC<HokkienExampleProps> = ({
  data,
  meaningIndex,
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const screens = useBreakpoint();

  const exampleChineseMap = useMemo(() => {
    const exampleWord = data?.examples?.map((item) => item?.exampleChinese);
    return exampleWord?.join(', ');
  }, [data]);

  const exampleHanjiMap = useMemo(() => {
    const exampleWord = data?.examples?.map((item) => item?.exampleHanji);
    return exampleWord?.join(', ');
  }, [data]);

  const exampleRomanMap = useMemo(() => {
    const exampleWord = data?.examples?.map((item) => item?.exampleRoman);
    return exampleWord?.join(', ');
  }, [data]);

  const exampleEnglishMap = useMemo(() => {
    const exampleWord = data?.examples
      ?.filter((item) => item.exampleEnglish)
      .map((item) => item?.exampleEnglish);
    if (Number(exampleWord?.length) <= 0) {
      return null;
    } else {
      return exampleWord?.join(', ');
    }
  }, [data]);

  const exampleMalayMap = useMemo(() => {
    const exampleWord = data?.examples
      ?.filter((item) => item?.exampleMalay)
      .map((item) => item?.exampleMalay);
    if (Number(exampleWord?.length) <= 0) {
      return null;
    } else {
      return exampleWord?.join(', ');
    }
  }, [data]);

  const exampleIndonesiaMap = useMemo(() => {
    const exampleWord = data?.examples
      ?.filter((item) => item?.exampleIndonesia)
      .map((item) => item?.exampleIndonesia);
    return exampleWord?.length > 0 ? exampleWord.join(', ') : null;
  }, [data]);

  const exampleTagalogMap = useMemo(() => {
    const exampleWord = data?.examples
      ?.filter((item) => item?.exampleTagalog)
      .map((item) => item?.exampleTagalog);
    return exampleWord?.length > 0 ? exampleWord.join(', ') : null;
  }, [data]);

  const navigateRelativeWord = useCallback(
    (relativeWordId: string) => {
      if (!relativeWordId) {
        return message.error('invalid word id');
      }
      navigate(`/search/word/${relativeWordId}`);
    },
    [navigate]
  );

  const WordGroup = ({ title, items }: { title: string; items?: any[] }) => (
    <div style={{ marginBottom: 16 }}>
      <Row gutter={[16, 16]} align='middle' wrap>
        <Col span={24}>
          <Text
            fontSize={14}
            fontWeight={600}
            style={{ color: '#636669', display: 'block' }}
            label={`${title}: `}
          />
        </Col>
        <Col span={24}>
          <Space size='small' wrap>
            {items?.map((item, index) => (
              <div
                title={item.relativeHanji}
                key={index}
                onClick={() => navigateRelativeWord(item.relativeWordId)}
                style={{
                  padding: '4px 12px',
                  border: '1px solid #7E5435',
                  borderRadius: '100px',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  textWrap: 'nowrap',
                  cursor: 'pointer',
                }}
              >
                <Text
                  fontSize={14}
                  style={{
                    color: '#7E5435',
                  }}
                  label={item.relativeHanji}
                />
              </div>
            ))}
          </Space>
        </Col>
      </Row>
    </div>
  );

  return (
    <>
      {/* Meaning content */}
      <Flex vertical gap={3}>
        <Text
          label={`${t('meaning')} ${Number(meaningIndex) + 1}`}
          fontWeight={600}
          fontSize={16}
        />
        {data?.partOfSpeech && (
          <Row align='middle'>
            <Col md={{ flex: '200px' }} xs={24}>
              <Text
                label={`${t('partOfSpeech')}`}
                style={{ width: '100%', textWrap: 'nowrap' }}
              />
            </Col>
            <Col md={1} xs={0}>
              <Text
                label={`: `}
                style={{ marginLeft: '8px', marginRight: '8px' }}
              />
            </Col>
            <Col md={16} xs={24}>
              <Text label={`${data?.partOfSpeech}`} />
            </Col>
          </Row>
        )}
        {data?.meaningChinese && (
          <Row align='middle'>
            <Col md={{ flex: '200px' }} xs={24}>
              <Text
                label={`${t('chineseDefinition')}`}
                style={{ width: '100%', textWrap: 'nowrap' }}
              />
            </Col>
            <Col md={1} xs={0}>
              <Text
                label={`:`}
                style={{ marginLeft: '8px', marginRight: '8px' }}
              />
            </Col>
            <Col md={16} xs={24}>
              <Text label={`${data?.meaningChinese}`} />
            </Col>
          </Row>
        )}
        {data.meaningEnglish && (
          <Row align='middle'>
            <Col xs={24} md={{ flex: '200px' }}>
              <Text
                label={`${t('englishDefinition')}`}
                style={{ width: '100%', textWrap: 'nowrap' }}
              />
            </Col>
            <Col xs={0} md={1}>
              <Text
                label={`:`}
                style={{ marginLeft: '8px', marginRight: '8px' }}
              />
            </Col>
            <Col xs={24} md={16}>
              <Text label={`${data?.meaningEnglish}`} />
            </Col>
          </Row>
        )}
        {data?.meaningHokkien && (
          <Row align='middle'>
            <Col md={{ flex: '200px' }} xs={24}>
              <Text
                label={`${t('hokkienDefinition')}`}
                style={{ width: '100%', textWrap: 'nowrap' }}
              />
            </Col>
            <Col md={1} xs={0}>
              <Text
                label={`: `}
                style={{ marginLeft: '8px', marginRight: '8px' }}
              />
            </Col>
            <Col md={16} xs={24}>
              <Text label={`${data?.meaningHokkien}`} />
            </Col>
          </Row>
        )}
        {data?.meaningMalay && (
          <Row align='middle'>
            <Col md={{ flex: '200px' }} xs={24}>
              <Text
                label={`${t('malayDefinition')}`}
                style={{ width: '100%', textWrap: 'nowrap' }}
              />
            </Col>
            <Col md={1} xs={0}>
              <Text
                label={`: `}
                style={{ marginLeft: '8px', marginRight: '8px' }}
              />
            </Col>
            <Col md={16} xs={24}>
              <Text label={`${data?.meaningMalay}`} />
            </Col>
          </Row>
        )}
        {data?.meaningIndonesia && (
          <Row align='middle'>
            <Col md={{ flex: '200px' }} xs={24}>
              <Text
                label={`${t('indonesianDefinition')}`}
                style={{ width: '100%', textWrap: 'nowrap' }}
              />
            </Col>
            <Col md={1} xs={0}>
              <Text
                label={`: `}
                style={{ marginLeft: '8px', marginRight: '8px' }}
              />
            </Col>
            <Col md={16} xs={24}>
              <Text label={`${data?.meaningIndonesia}`} />
            </Col>
          </Row>
        )}
        {data?.meaningTagalog && (
          <Row align='middle'>
            <Col md={{ flex: '200px' }} xs={24}>
              <Text
                label={`${t('tagalogDefinition')}`}
                style={{ width: '100%', textWrap: 'nowrap' }}
              />
            </Col>
            <Col md={1} xs={0}>
              <Text
                label={`: `}
                style={{ marginLeft: '8px', marginRight: '8px' }}
              />
            </Col>
            <Col md={16} xs={24}>
              <Text label={`${data?.meaningTagalog}`} />
            </Col>
          </Row>
        )}
      </Flex>

      {/* Example sentences */}
      {data?.examples?.length > 0 && (
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            border: '1px solid #D0D5DD',
            borderRadius: 10,
            padding: 24,
            backgroundColor: '#fff',
            marginTop: 16,
          }}
        >
          <Space direction='vertical'>
            <Text
              label={t('example')}
              fontWeight={600}
              style={{
                color: '#7E5435',
              }}
            />
            <Text label={exampleHanjiMap} />
            <Text label={exampleRomanMap} style={{ marginTop: 8 }} />
            {exampleChineseMap && (
              <Row>
                <Col xs={24} md={{ flex: '130px' }}>
                  <Text
                    label={` ${t('exampleChineseSentence')}`}
                    style={{ color: '#667085', marginTop: 8 }}
                  />
                </Col>
                <Col xs={0} md={1}>
                  <Text
                    label={`:`}
                    style={{ color: '#667085', marginTop: 8 }}
                  />
                </Col>
                <Col xs={24} md={19}>
                  <Text
                    label={`${exampleChineseMap ?? '-'}`}
                    style={{ color: '#667085', marginTop: 8 }}
                  />
                </Col>
              </Row>
            )}
            {exampleEnglishMap && (
              <Row>
                <Col xs={24} md={{ flex: '130px' }}>
                  <Text
                    label={` ${t('exampleEnglishSentence')}`}
                    style={{ color: '#667085', marginTop: 8 }}
                  />
                </Col>
                <Col xs={0} md={1}>
                  <Text
                    label={`:`}
                    style={{ color: '#667085', marginTop: 8 }}
                  />
                </Col>
                <Col xs={24} md={19}>
                  <Text
                    label={`${exampleEnglishMap ?? '-'}`}
                    style={{ color: '#667085', marginTop: 8 }}
                  />
                </Col>
              </Row>
            )}
            {exampleMalayMap && (
              <Row>
                <Col xs={24} md={{ flex: '130px' }}>
                  <Text
                    label={` ${t('exampleMalaySentence')}`}
                    style={{ color: '#667085', marginTop: 8 }}
                  />
                </Col>
                <Col xs={0} md={1}>
                  <Text
                    label={`:`}
                    style={{ color: '#667085', marginTop: 8 }}
                  />
                </Col>
                <Col xs={24} md={19}>
                  <Text
                    label={`${exampleMalayMap ?? '-'}`}
                    style={{ color: '#667085', marginTop: 8 }}
                  />
                </Col>
              </Row>
            )}
            {exampleIndonesiaMap && (
              <Row>
                <Col xs={24} md={{ flex: '130px' }}>
                  <Text
                    label={` ${t('exampleIndonesianSentence')}`}
                    style={{ color: '#667085', marginTop: 8 }}
                  />
                </Col>
                <Col xs={0} md={1}>
                  <Text
                    label={`:`}
                    style={{ color: '#667085', marginTop: 8 }}
                  />
                </Col>
                <Col xs={24} md={19}>
                  <Text
                    label={`${exampleIndonesiaMap}`}
                    style={{ color: '#667085', marginTop: 8 }}
                  />
                </Col>
              </Row>
            )}
            {exampleTagalogMap && (
              <Row>
                <Col xs={24} md={{ flex: '130px' }}>
                  <Text
                    label={` ${t('exampleTagalogSentence')}`}
                    style={{ color: '#667085', marginTop: 8 }}
                  />
                </Col>
                <Col xs={0} md={1}>
                  <Text
                    label={`:`}
                    style={{ color: '#667085', marginTop: 8 }}
                  />
                </Col>
                <Col xs={24} md={19}>
                  <Text
                    label={`${exampleTagalogMap}`}
                    style={{ color: '#667085', marginTop: 8 }}
                  />
                </Col>
              </Row>
            )}
          </Space>
        </div>
      )}
      {/* Meaning-level synonyms and antonyms */}
      <Row gutter={[16, 24]} wrap style={{ margin: '16px 0' }}>
        <Col style={{ padding: 0 }}>
          {data?.meaningWordSynonyms?.length > 0 && (
            <WordGroup
              title={t('meaningWordSynonym')}
              items={data.meaningWordSynonyms}
            />
          )}
        </Col>
        <Col style={{ padding: 0 }}>
          {data?.meaningWordAntonyms?.length > 0 && (
            <WordGroup
              title={t('meaningWordAntonym')}
              items={data.meaningWordAntonyms}
            />
          )}
        </Col>
        <Col style={{ padding: 0 }}>
          {data?.meaningSynonyms?.length > 0 && (
            <WordGroup
              title={t('meaningSynonym')}
              items={data.meaningSynonyms}
            />
          )}
        </Col>
        <Col style={{ padding: 0 }}>
          {data?.meaningAntonyms?.length > 0 && (
            <WordGroup
              title={t('meaningAntonym')}
              items={data.meaningAntonyms}
            />
          )}
        </Col>
      </Row>
    </>
  );
};

export default HokkienExample;
