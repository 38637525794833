import React from 'react';
import { useQuery } from '@tanstack/react-query';
import { Alert, Skeleton } from 'antd';
import { fetchPublicAnnouncements } from '../api/HomeAPI';
import { Announcement } from '../types/HomeType';
import NewsTicker from '../../../components/ui/NewsTicker';

const AnnouncementsComponent: React.FC = () => {
  const { data, error, isLoading } = useQuery({
    queryKey: ['publicAnnouncements'],
    queryFn: () => fetchPublicAnnouncements(),
  });

  if (isLoading) return <Skeleton.Button shape='default' block active />;
  if (error)
    return (
      <Alert
        message='Error'
        description={(error as Error).message}
        type='error'
      />
    );

  const announcements =
    data?.results.map(
      (announcement: Announcement) => announcement.description
    ) || [];

  // If there are no announcements, return null
  if (announcements.length === 0) return null;

  return <NewsTicker announcements={announcements} />;
};

export default AnnouncementsComponent;
