export const convertTextToStrokeTone = (str: string): string => {
  // Define tone mappings
  const toneMap: { [key: string]: string[] } = {
    a: ['', 'á', 'à', '', 'â', 'ǎ', 'ā', '', 'a̋'],
    e: ['', 'é', 'è', '', 'ê', 'ě', 'ē', '', 'e̋'],
    i: ['', 'í', 'ì', '', 'î', 'ǐ', 'ī', '', 'i̋'],
    o: ['', 'ó', 'ò', '', 'ô', 'ǒ', 'ō', '', 'ő'],
    u: ['', 'ú', 'ù', '', 'û', 'ǔ', 'ū', '', 'ű'],
    A: ['', 'Á', 'À', '', 'Â', 'Ǎ', 'Ā', '', 'A̋'],
    E: ['', 'É', 'È', '', 'Ê', 'Ě', 'Ē', '', 'E̋'],
    I: ['', 'Í', 'Ì', '', 'Î', 'Ǐ', 'Ī', '', 'I̋'],
    O: ['', 'Ó', 'Ò', '', 'Ô', 'Ǔ', 'Ō', '', 'Ő'],
    U: ['', 'Ú', 'Ù', '', 'Û', 'Ǔ', 'Ū', '', 'Ű'],
  };

  // Regular expression to match string with tone numbers
  const regex = /([a-zA-ZüÜ]+)([1-9])/gi;

  // Replace string with tone numbers with diacritical marks
  return str.replace(regex, (match, strPart, tone) => {
    const toneNumber = parseInt(tone, 10) - 1; // Convert tone to 0-based index

    // Determine which vowel should receive the tone mark
    const targetVowel = strPart.match(/[aeiouüAEIOUÜ]/g);

    if (targetVowel) {
      if (targetVowel.length > 1) {
        // Apply tone mark to the first vowel unless it’s a specific sequence
        if (['a', 'e', 'o', 'A', 'E', 'O'].includes(targetVowel[0])) {
          return strPart.replace(
            targetVowel[0],
            toneMap[targetVowel[0]][toneNumber]
          );
        } else {
          // return original string if code value is null
          if (toneMap[targetVowel[1]][toneNumber] === '') {
            return strPart;
          } else {
            return strPart.replace(
              targetVowel[1],
              toneMap[targetVowel[1]][toneNumber]
            );
          }
        }
      } else {
        if (toneMap[targetVowel[0]][toneNumber] === '') {
          return strPart;
        } else {
          return strPart.replace(
            targetVowel[0],
            toneMap[targetVowel[0]][toneNumber]
          );
        }
      }
    }

    return match; // Return original if no match found
  });
};
