import axiosInstance from '../../../common/axiosInstance';
import { ResetPasswordModel } from '../types/ResetPassword';

// Forgot password function
export const resetPassword = async (
  data: ResetPasswordModel,
  token: string
) => {
  const response = await axiosInstance.post(
    `/auth/reset-password?token=${token}`,
    data
  );
  return response.data;
};
