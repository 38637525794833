import { Button, Input, Row } from 'antd';
import { forwardRef, useCallback } from 'react';
import {
  Control,
  Controller,
  FormState,
  UseFormSetValue,
} from 'react-hook-form';
import { convertTextToStrokeTone } from '../../../utils/convertToneToStroke';
import { isChinese } from '../../../utils/characterChecking';
import { ISearch } from '../../../types/GenerateTypes';
import { useTranslation } from 'react-i18next';

interface IBasicSearch {
  control: Control<ISearch['basic']>;
  setValue: UseFormSetValue<ISearch['basic']>;
  onSubmit: (e: any) => void;
  formState?: FormState<ISearch['basic']>;
}
const BasicSearch = forwardRef(
  ({ control, setValue, onSubmit, formState }: IBasicSearch) => {
    const { t } = useTranslation();

    const errors = formState?.errors;
    return (
      <form onSubmit={onSubmit}>
        <Row justify='center'>
          <Controller
            control={control}
            name='value'
            render={({ field }) => (
              <Input.Search
                {...field}
                placeholder={t('search')}
                onChange={(e) => {
                  let value = e.target.value;
                  field.onChange(e);
                  const endOfCharacter =
                    e.target.value[e.target.value.length - 1];
                  if (/[0-9]/.test(endOfCharacter)) {
                    setTimeout(() => {
                      value = convertTextToStrokeTone(e.target.value);
                      setValue('value', value);
                      setValue(
                        'type',
                        isChinese(value) ? 'chinese' : 'english'
                      );
                    }, 500);
                  } else {
                    setValue('value', value);
                    setValue('type', isChinese(value) ? 'chinese' : 'english');
                  }
                }}
                enterButton={
                  <Button type='primary' htmlType='submit'>
                    {t('search')}
                  </Button>
                }
              />
            )}
          />
          {errors?.value && (
            <span style={{ margin: '5px 0' }}>{errors.value.message}</span>
          )}
        </Row>
      </form>
    );
  }
);

BasicSearch.displayName = 'BasicSearch';

export default BasicSearch;
