import React from 'react';
import Text from '../../components/base/Text';
import { Button, Flex, Typography } from 'antd';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { HomeOutlined } from '@ant-design/icons';

const { Title } = Typography;

export default function NotFoundPage() {
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <Flex
      style={{
        height: '100vh',
        background: 'linear-gradient(135deg, #f5f7fa 0%, #c3cfe2 100%)',
        alignItems: 'center',
        justifyContent: 'center',
        padding: '0 24px',
      }}
      vertical
    >
      <Flex
        gap={20}
        vertical
        style={{
          maxWidth: '460px',
          justifyContent: 'center',
          alignItems: 'center',
          background: 'white',
          padding: '40px',
          borderRadius: '16px',
          boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
        }}
      >
        <Title
          level={1}
          style={{
            fontSize: '120px',
            margin: 0,
            color: '#7E5435',
            textShadow: '2px 2px 4px rgba(0,0,0,0.1)',
          }}
        >
          404
        </Title>
        <Title level={2} style={{ margin: 0, textAlign: 'center' }}>
          {t('notFoundTitle')}
        </Title>
        <Text
          label={t('notFoundSubTitle')}
          style={{
            fontSize: '16px',
            color: '#666',
            textAlign: 'center',
            maxWidth: '300px',
          }}
        />
        <Button
          type='primary'
          icon={<HomeOutlined />}
          onClick={() => navigate('/')}
          size="large"
          style={{
            marginTop: '20px',
            height: '48px',
            fontSize: '16px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          {t('backToHome')}
        </Button>
      </Flex>
    </Flex>
  );
}
