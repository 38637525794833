import React, { useCallback, useContext, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useForm, Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Typography, Form, Input, Button, message, Modal } from 'antd';
import { zodResolver } from '@hookform/resolvers/zod';
import { LoginModel, LoginSchema } from '../../common/type/loginType';
import { AuthContext } from '../../context/AuthContext';
import { useMutation } from '@tanstack/react-query';
import axiosInstance from '../../common/axiosInstance';
import { login } from '../../common/api/auth';
import backgroundSvg from '../../assets/svg/login-background.svg';
import { useMediaQuery } from 'react-responsive';
import { logoUrl } from '../../utils/constant';
import { CheckCircleFilled } from '@ant-design/icons';

const { info } = Modal;
const { Text } = Typography;

const LoginPage = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const authContext = useContext(AuthContext);
  const isMobile = useMediaQuery({ maxWidth: 767 });

  const {
    handleSubmit,
    control,
    reset,
    formState: { errors },
  } = useForm<LoginModel>({
    resolver: zodResolver(LoginSchema),
  });

  const { mutate: loginMutate, isPending } = useMutation({
    mutationFn: login,
  });

  const onSubmit = (data: LoginModel) => {
    loginMutate(data, {
      onSuccess: (data) => {
        message.success(t('signinSuccess'));
        authContext?.login(data);
        navigate('/');
      },
      onError: (error) => {
        handleLoginError(error);
      },
    });
  };

  const handleLoginError = (error: any) => {
    reset();
    if (error.response) {
      message.error(error.response.data.message || error.response.data.error);
    } else if (error.request) {
      message.error(t('networkError'));
    } else {
      message.error(t('loginError'));
    }
    console.error('Login error: ', error);
  };

  return (
    <main
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        background: `url(${backgroundSvg}) no-repeat center center `,
        backgroundSize: 'cover',
        height: '100vh',
        padding: '0 20px',
      }}
    >
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Link to='/'>
          <img src={logoUrl} alt='logo' style={{ width: '80px' }} />
        </Link>
        <div style={{ height: '20px' }} />
        <div
          style={{
            padding: '40px',
            margin: '0 16px', // Only horizontal margin
            borderRadius: '8px',
            boxShadow:
              '0px 4px 8px -4px rgba(255, 36, 66, 0.03), 0px 20px 30px -4px rgba(255, 36, 66, 0.08)',
            background: 'white',
            width: isMobile ? 'auto' : '560px',
            boxSizing: 'border-box',
          }}
        >
          <Text style={{ color: '#667085' }}>{t('welcomeBack')} 👋</Text>
          <div />
          <Text style={{ color: '#333333', fontSize: 20, fontWeight: 600 }}>
            {t('signInToYourAccount')}
          </Text>
          <div style={{ height: '24px' }} />
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <Form onFinish={handleSubmit(onSubmit)} layout='vertical'>
                <Form.Item
                  label={t('email')}
                  name='email'
                  style={{ color: '#344054' }}
                  validateStatus={errors.email ? 'error' : ''}
                  help={errors.email?.message}
                >
                  <Controller
                    name='email'
                    control={control}
                    defaultValue=''
                    render={({ field }) => (
                      <Input
                        {...field}
                        type='email'
                        autoComplete='email'
                        placeholder={t('email')}
                      />
                    )}
                  />
                </Form.Item>
                <Form.Item
                  label={t('password')}
                  name='password'
                  style={{ color: '#344054' }}
                  validateStatus={errors.password ? 'error' : ''}
                  help={errors.password?.message}
                >
                  <Controller
                    name='password'
                    control={control}
                    defaultValue=''
                    render={({ field }) => (
                      <Input.Password {...field} placeholder={t('password')} />
                    )}
                  />
                </Form.Item>
                <div style={{ height: '16px' }} />
                <Form.Item>
                  <div style={{ display: 'flex', flexDirection: 'column' }}>
                    <Button
                      type='primary'
                      htmlType='submit'
                      block
                      loading={isPending}
                      style={{ minHeight: 44 }}
                    >
                      {t('signIn')}
                    </Button>
                  </div>
                </Form.Item>
              </Form>
            </div>
            <Link
              style={{
                alignSelf: 'center',
                color: '#7E5435',
                fontSize: '14px',
              }}
              to={'/forgot-password'}
            >
              {t('forgotYourPassword')}
            </Link>
          </div>
        </div>
        <div style={{ height: '60px' }} />
        <div>
          <Text style={{ color: '#667085' }}>
            {t('dontHaveAnAccount')}{' '}
            <Link style={{ color: '#7E5435', fontWeight: 600 }} to='/register'>
              {t('signUp')}
            </Link>
          </Text>
        </div>
      </div>
    </main>
  );
};

export default LoginPage;
