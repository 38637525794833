import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { AuthProvider } from "./context/AuthContext";
import { PrivateRoute, PublicRoute } from "./utils/RouteHandlers";
import Login from "./pages/login/Login";
import SignupPage from "./pages/Signup/Signup";
import ForgotPassword from "./pages/ForgotPassword/ForgotPassword";
import Home from "./pages/home/Home";
import WordDetail from "./pages/WordDetail/WordDetail";
import SearchResults from "./pages/SearchResults/SearchResults";
import Leaderboard from "./pages/Leaderboard/Leaderboard";
import Profile from "./pages/profile/Profile";
import LikeContextProvider from "./context/LikeContext";
import ResetPassword from "./pages/ResetPassword";
import VerifyEmail from "./pages/VerifyEmail/VerifyEmail";
import NotFoundPage from "./pages/notFoundPage/notFoundPage";
import ScrollToTop from "./components/ScrollToTop";

// Import your components here

const queryClient = new QueryClient();

const App = () => {
  return (
    <QueryClientProvider client={queryClient}>
      <BrowserRouter basename='/'>
        <AuthProvider>
          <LikeContextProvider>
            <ScrollToTop />
            <Routes>
              {/* Public routes that should redirect authenticated users */}
              <Route element={<PublicRoute restrictedWhenAuth={true} />}>
                <Route path='/login' element={<Login />} />
                <Route path='/register' element={<SignupPage />} />
                <Route path='/forgot-password' element={<ForgotPassword />} />
                <Route path='/reset-password' element={<ResetPassword />} />
              </Route>

              {/* Public routes accessible to all users */}
              <Route element={<PublicRoute restrictedWhenAuth={false} />}>
                <Route path='/' element={<Home />} />
                <Route path='/search/word/:wordId' element={<WordDetail />} />
                <Route path='/search' element={<SearchResults />} />
                <Route path='/leaderboard' element={<Leaderboard />} />
                <Route path='/verify-email' element={<VerifyEmail />} />
              </Route>

              {/* Private routes */}
              <Route element={<PrivateRoute />}>
                <Route path='/profile' element={<Profile />} />
              </Route>
              <Route path='*' element={<NotFoundPage />} />
            </Routes>
          </LikeContextProvider>
        </AuthProvider>
      </BrowserRouter>
    </QueryClientProvider>
  );
};

export default App;
