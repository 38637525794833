import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import homegirl from '../../assets/svg/home-girl.svg';
import {
  Typography,
  Form,
  Input,
  Button,
  message,
  Image,
  Space,
  Layout,
  Menu,
  Alert,
  Row,
  Col,
  Radio,
  Select,
  Flex,
} from 'antd';
import {
  CommentOutlined,
  DownOutlined,
  GlobalOutlined,
  HeartOutlined,
  SearchOutlined,
  SoundOutlined,
  UpOutlined,
} from '@ant-design/icons';
import HokkienWordBox from '../../components/ui/HokkienWordBox';
import { useCallback, useState } from 'react';
import HokkienDictionaryHeader from '../../components/layout/HokkienDictionaryHeader';
import HokkienDictionaryFooter from '../../components/layout/HokkienDictionaryFooter';
import NewsTicker from '../../components/ui/NewsTicker';
import { useQuery } from '@tanstack/react-query';
import RandomWordsComponent from './view/RandomWordsComponent';
import AnnouncementsComponent from './view/AnnouncementComponent';
import AdvanceSearchComponent from './view/AdvanceSearchComponent';
import { Controller, useForm, useWatch } from 'react-hook-form';
import { InitialFilter } from '../../constant/home';
import { isChinese } from '../../utils/characterChecking';
import { useQueryRouteHandler } from '../../utils/RouteHandlers';
import { useMediaQuery } from 'react-responsive';
import { convertTextToStrokeTone } from '../../utils/convertToneToStroke';

const { Header, Content, Footer } = Layout;
const { Text, Title, Link } = Typography;

const Home = () => {
  const { i18n, t } = useTranslation();
  const navigate = useNavigate();
  const { parsingObjToQuery } = useQueryRouteHandler();
  const isMobile = useMediaQuery({ query: '(max-width: 768px)' });
  const [isAdvancedSearchOpen, setIsAdvancedSearchOpen] = useState(false);

  // RHF
  const { control, setValue, reset, getValues, formState, handleSubmit } =
    useForm({
      defaultValues: InitialFilter,
      mode: 'all',
    });

  const { isDirty, isValid } = formState;
  const watchBasicSearch = useWatch({ control, name: 'searchQuery' });

  const onSearch = useCallback(
    (values: any) => {
      const searchQuery = values?.searchQuery;
      const encodedQuery = encodeURIComponent(searchQuery);
      const payload = {
        value: values.searchQuery,
        type: isChinese(searchQuery) ? 'chinese' : 'english',
      };

      navigate(`/search?${parsingObjToQuery(payload)}`);
    },
    [navigate, parsingObjToQuery]
  );

  return (
    <Layout>
      <HokkienDictionaryHeader />
      <Content>
        <div
          style={{
            maxWidth: 1100,
            margin: isMobile ? undefined : '0 auto',
            padding: '0 16px',
          }}
        >
          <Row justify={'center'}>
            <Col
              xs={12}
              span={10}
              style={{ justifyContent: 'center', display: 'flex' }}
            >
              <img src={homegirl} style={{ height: '300px', width: '300px' }} />
            </Col>

            <Col xs={24} sm={12} span={12}>
              <div
                style={{
                  transition: 'opacity 0.3s ease-in-out',
                  opacity: isAdvancedSearchOpen ? 0 : 1,
                }}
              >
                {!isAdvancedSearchOpen && (
                  <div
                    style={{
                      paddingTop: 16,
                    }}
                  >
                    <Title
                      level={isMobile ? 5 : 2}
                      style={{ color: '#7E5435', fontWeight: 600 }}
                    >
                      {t('hokkienDictionary')}
                    </Title>
                    <form onSubmit={handleSubmit(onSearch)}>
                      <Flex gap={10}>
                        <Controller
                          control={control}
                          name={'searchQuery'}
                          rules={{ required: true }}
                          render={({ field }) => (
                            <Input
                              {...field}
                              onChange={(e) => {
                                let value = e.target.value;
                                field.onChange(e);
                                const endOfCharacter =
                                  e.target.value[e.target.value.length - 1];
                                if (/[0-9]/.test(endOfCharacter)) {
                                  setTimeout(() => {
                                    value = convertTextToStrokeTone(
                                      e.target.value
                                    );
                                    setValue('searchQuery', value);
                                  }, 500);
                                } else {
                                  setValue('searchQuery', value);
                                }
                              }}
                              placeholder={t('dictionaryExamples')}
                              style={{ width: '100%' }}
                            />
                          )}
                        />
                        <Button
                          type='primary'
                          htmlType='submit'
                          icon={<SearchOutlined />}
                          disabled={!watchBasicSearch}
                        >
                          {t('searchDictionary')}
                        </Button>
                      </Flex>
                    </form>
                  </div>
                )}
              </div>
              <div style={{ height: '8px' }} />
              <Typography
                style={{
                  margin: !isAdvancedSearchOpen ? '' : '10px 0',
                  color: '#7E5435',
                  flexDirection: 'row',
                  display: 'flex',
                  alignItems: 'center',
                  cursor: 'pointer',
                }}
                onClick={() => {
                  setValue('searchQuery', '');
                  setIsAdvancedSearchOpen(!isAdvancedSearchOpen);
                }}
              >
                {t('advancedSearch')}
                <div style={{ width: 8 }} />
                {isAdvancedSearchOpen ? (
                  <UpOutlined style={{ fontSize: 12 }} />
                ) : (
                  <DownOutlined style={{ fontSize: 12 }} />
                )}
              </Typography>
              {isAdvancedSearchOpen && (
                <AdvanceSearchComponent
                  control={control}
                  setValue={setValue}
                  reset={reset}
                  getValues={getValues}
                  formState={formState}
                />
              )}
            </Col>
          </Row>
          <div style={{ height: 24 }} />
          <AnnouncementsComponent />
          <div style={{ height: 24 }} />
          <RandomWordsComponent />
        </div>
        <div style={{ height: 92 }} />
      </Content>
      <HokkienDictionaryFooter />
    </Layout>
  );
};

export default Home;
