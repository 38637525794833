import { ISearch } from '../types/GenerateTypes';

export const InitialFilter = {
  searchType: 'fuzzy',
  searchQuery: '',
  roman: '',
  hanji: '',
  english: '',
  chinese: '',
  malay: '',
};

export const InitialBasicSearch = {
  type: '',
  value: '',
};

export const InitialAdvanceSearch: ISearch['advance'] = {
  searchType: 'fuzzy',
  roman: '',
  hanji: '',
  english: '',
  chinese: '',
  malay: '',
};
