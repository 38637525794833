import axiosInstance from "../../../common/axiosInstance";
import { IGeneralResponse } from "../../../types/GenerateTypes";
import { IWordListsResponse } from "../../../types/word";
import {
  AnnouncementsParams,
  AnnouncementsResponse,
  RandomWordsApiResponse,
  RandomWordsResponse,
} from "../types/HomeType";

export const fetchRandomWords = async (): Promise<RandomWordsApiResponse> => {
  try {
    const response =
      await axiosInstance.get<RandomWordsApiResponse>("/random-words");
    return response.data;
  } catch (error) {
    console.error("Error fetching random words:", error);
    throw error;
  }
};

export const fetchPublicAnnouncements = async (
  params: AnnouncementsParams = {}
): Promise<AnnouncementsResponse> => {
  try {
    const response = await axiosInstance.get<AnnouncementsResponse>(
      "/announcements/public",
      { params }
    );
    return response.data;
  } catch (error) {
    console.error("Error fetching public announcements:", error);
    throw error;
  }
};
