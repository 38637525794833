import { Button, Col, Flex, Radio, Row, Space } from 'antd';
import { forwardRef, useCallback } from 'react';
import Text from '../../../components/base/Text';
import { useTranslation } from 'react-i18next';
import {
  Control,
  Controller,
  UseFormReset,
  UseFormSetValue,
  useWatch,
} from 'react-hook-form';
import InputText from '../../../components/base/InputText';
import { convertTextToStrokeTone } from '../../../utils/convertToneToStroke';
import { CloseOutlined, SearchOutlined } from '@ant-design/icons';
import { ISearch } from '../../../types/GenerateTypes';

interface IAdvanceSearchProps {
  control: Control<ISearch['advance']>;
  setValue: UseFormSetValue<ISearch['advance']>;
  onSubmit: (e: any) => void;
  reset: UseFormReset<ISearch['advance']>;
}
const AdvanceSearch = forwardRef(
  ({ control, setValue, onSubmit, reset }: IAdvanceSearchProps) => {
    const { t } = useTranslation();

    const watchHanji = useWatch({ control, name: 'hanji' });
    const watchRoman = useWatch({ control, name: 'roman' });
    const watchChinese = useWatch({ control, name: 'chinese' });
    const watchEnglish = useWatch({ control, name: 'english' });
    const watchMalay = useWatch({ control, name: 'malay' });

    const handleClearForm = useCallback(() => {
      reset();
    }, [reset]);
    return (
      <form onSubmit={onSubmit}>
        <Space direction='vertical' size='large'>
          <Flex gap={10} wrap='wrap' align='center'>
            <Text label={t('filteringMethod')} fontWeight={600} />

            <Controller
              control={control}
              name='searchType'
              render={({ field }) => (
                <Radio.Group {...field} value={field.value}>
                  <Radio value='exact'>{t('exactSearch')}</Radio>
                  <Radio value='fuzzy'>{t('fuzzySearch')}</Radio>
                </Radio.Group>
              )}
            />
          </Flex>
          <Row gutter={[16, 8]}>
            <Col lg={12}>
              <Controller
                control={control}
                name='roman'
                render={({ field }) => (
                  <InputText
                    {...field}
                    onChange={(e) => {
                      let value = e.target.value;
                      field.onChange(e);
                      const endOfCharacter =
                        e.target.value[e.target.value.length - 1];
                      if (/[0-9]/.test(endOfCharacter)) {
                        setTimeout(() => {
                          value = convertTextToStrokeTone(e.target.value);
                          setValue('roman', value);
                        }, 500);
                      } else {
                        setValue('roman', value);
                      }
                    }}
                    label={t('romanisedHokkien')}
                    placeholder={t('enterKeywords')}
                  />
                )}
              />
            </Col>

            <Col lg={12}>
              <Controller
                control={control}
                name='hanji'
                render={({ field }) => (
                  <InputText
                    {...field}
                    label={t('chineseAndRomanisedHokkien')}
                    placeholder={t('enterKeywords')}
                  />
                )}
              />
            </Col>

            <Col lg={12}>
              <Controller
                control={control}
                name='english'
                render={({ field }) => (
                  <InputText
                    {...field}
                    label={t('correspondingEnglish')}
                    placeholder={t('recommendFuzzySearch')}
                  />
                )}
              />
            </Col>

            <Col lg={12}>
              <Controller
                control={control}
                name='chinese'
                render={({ field }) => (
                  <InputText
                    {...field}
                    label={t('correspondingChinese')}
                    placeholder={t('recommendFuzzySearch')}
                  />
                )}
              />
            </Col>
            <Col lg={12}>
              <Controller
                control={control}
                name='malay'
                render={({ field }) => (
                  <InputText
                    {...field}
                    label={t('correspondingMalay')}
                    placeholder={t('recommendFuzzySearch')}
                  />
                )}
              />
            </Col>
          </Row>
          <Flex gap={10} justify='end'>
            <Button icon={<CloseOutlined />} onClick={handleClearForm}>
              {t('clear')}
            </Button>
            <Button
              icon={<SearchOutlined />}
              type='primary'
              htmlType='submit'
              disabled={
                !watchChinese &&
                !watchEnglish &&
                !watchHanji &&
                !watchRoman &&
                !watchMalay
              }
            >
              {t('search')}
            </Button>
          </Flex>
        </Space>
      </form>
    );
  }
);

AdvanceSearch.displayName = 'AdvanceSearch';
export default AdvanceSearch;
