import React, { createContext, useState, ReactNode } from 'react';
import { IWordListsResponse } from '../types/word';

interface LikeObject {
  [key: string]: {
    value: boolean | undefined;
    totalLike: number;
    totalDislike: number;
  };
}
type TWords = IWordListsResponse & { isLike?: boolean };
interface ILikeContext {
  likes: LikeObject;
  setLike: (id: string) => void;
  setDislike: (id: string) => void;
  setInitialLikes: (data: TWords[]) => void;
}

export const LikeContext = createContext<ILikeContext | undefined>(undefined);

export const LikeContextProvider = ({ children }: { children: ReactNode }) => {
  const [likes, setLikes] = useState<LikeObject>({});

  const setInitialLikes = (data: TWords[]) => {
    const wordLikes: LikeObject = data.reduce((acc, curr) => {
      acc[curr.wordId] = {
        value: curr.isLike,
        totalLike: Number(curr?.totalLike ?? 0),
        totalDislike: Number(curr?.totalDislike ?? 0),
      };
      return acc;
    }, {} as LikeObject);
    setLikes(wordLikes);
  };

  const setLike = (id: string) => {
    setLikes((prev) => ({
      ...prev,
      [id]: {
        value: true,
        totalLike: Number(prev[id]?.totalLike || 0) + 1,
        totalDislike:
          Number(prev[id]?.totalDislike) <= 0
            ? Number(prev[id]?.totalDislike ?? 0)
            : Number(prev[id]?.totalDislike) - 1,
      },
    }));
  };
  const setDislike = (id: string) => {
    setLikes((prev) => ({
      ...prev,
      [id]: {
        value: false,
        totalDislike: Number(prev[id]?.totalDislike) + 1,
        totalLike:
          Number(prev[id]?.totalLike ?? 0) <= 0
            ? Number(prev[id]?.totalLike ?? 0)
            : Number(prev[id]?.totalLike) - 1,
      },
    }));
  };
  return (
    <LikeContext.Provider
      value={{
        likes,
        setLike,
        setDislike,
        setInitialLikes,
      }}
    >
      {children}
    </LikeContext.Provider>
  );
};

export default LikeContextProvider;
