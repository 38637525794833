import React, {
  useState,
  useEffect,
  useContext,
  useCallback,
  useRef,
  useMemo,
} from 'react';
import { Button, Col, Flex, Grid, Layout, Row, Skeleton, Tooltip } from 'antd';
import { useForm, SubmitHandler, Controller } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import * as z from 'zod';
import {
  useParams,
  useNavigate,
  useSearchParams,
  useLocation,
} from 'react-router-dom';
import HokkienDictionaryHeader from '../../components/layout/HokkienDictionaryHeader';
import HokkienDictionaryFooter from '../../components/layout/HokkienDictionaryFooter';
import HokkienWordBox from '../../components/ui/HokkienWordBox';
import ContributeWordBox from '../../components/ui/ContributeWordBox';
import { useTranslation } from 'react-i18next';
import { ReactComponent as SearchNotFoundIcon } from '../../assets/svg/search-not-found.svg';
import ContributeSuccessModal from '../../components/ui/ContributeSuccessModal';
import LoginRequiredModal from '../../components/ui/LoginRequiredModal';
import { AuthContext } from '../../context/AuthContext';
import { useQueryRouteHandler } from '../../utils/RouteHandlers';
import Text from '../../components/base/Text';
import {
  DislikeOutlined,
  LikeOutlined,
  LoadingOutlined,
  SoundFilled,
  SoundOutlined,
} from '@ant-design/icons';
import { ReactComponent as StopIcon } from '../../assets/svg/stop-icon.svg';
import { IWordListsResponse } from '../../types/word';
import { fetchLikesByUser, fetchWordBySearch } from './api/SearchAPI';
import { useMutation, useQuery } from '@tanstack/react-query';
import { isChinese } from '../../utils/characterChecking';
import { postLike } from './api/LikeAPI';
import { LikeContext } from '../../context/LikeContext';
import ModalConfirm from '../../components/base/ModalConfirm';
import Pagination from '../../components/layout/Pagination';
import { ISearch } from '../../types/GenerateTypes';
import AdvanceSearch from './ui/AdvanceSearch';
import { InitialAdvanceSearch, InitialBasicSearch } from '../../constant/home';
import BasicSearch from './ui/BasicSearch';

const { Content } = Layout;
const { useBreakpoint } = Grid;

type TSelectedWord = IWordListsResponse & { isLike?: boolean };

const SearchResults: React.FC = () => {
  const querySearch = useLocation();
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const screens = useBreakpoint();
  const { t, i18n } = useTranslation();
  const authContext = useContext(AuthContext);
  const audioRef = useRef<HTMLAudioElement>(null);

  const searchSchema = useMemo(() => z.object({
    value: z.string().min(1, t('searchRequired')),
    type: z.string(),
  }), [t]);

  const [searchResults, setSearchResults] = useState<IWordListsResponse[]>([]);
  const [isSuccessModalVisible, setIsSuccessModalVisible] = useState(false);
  const [isLoginModalVisible, setIsLoginModalVisible] = useState(false);
  const [selectedWord, setSelectedWord] = useState<TSelectedWord | null>(null);
  const [validAudioURL, setValidAudioURL] = useState(true);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isPlaying, setIsPlaying] = useState<boolean>(false);
  const [isLike, setIslike] = useState<boolean | undefined>(
    selectedWord?.isLike
  );
  const [modalOptions, setModalOptions] = useState({
    isConfirmOpen: false,
  });
  const [pagination, setPagination] = useState({
    page: 1,
    limit: 11,
  });

  const [searchType, setSearchType] = useState<'basic' | 'advance'>('basic');

  const { getQueryParams, parsingObjToQuery } = useQueryRouteHandler();

  // Temporarily get user info from authContext or localStorage
  const user =
    authContext?.user || JSON.parse(localStorage.getItem('user') || 'null');

  // like Context
  const likeContext = useContext(LikeContext);
  if (!likeContext) {
    throw new Error('LikeButton must be used within a LikeProvider');
  }

  const { likes, setDislike, setLike, setInitialLikes } = likeContext;

  // RHF
  const {
    control: controlBasic,
    handleSubmit: handleSubmitBasic,
    setValue: setValueBasic,
    getValues: getValuesBasic,
    formState,
  } = useForm<ISearch['basic']>({
    resolver: zodResolver(searchSchema),
    defaultValues: InitialBasicSearch,
    mode: 'all',
  });
  const {
    control: controlAdvance,
    setValue: setValueAdvance,
    reset: resetAdvance,
    handleSubmit: handleSubmitAdvance,
  } = useForm<ISearch['advance']>({
    defaultValues: InitialAdvanceSearch,
    mode: 'all',
  });

  // React Query
  const { mutate: createLike } = useMutation({
    mutationKey: ['likes'],
    mutationFn: postLike,
  });

  const {
    data: wordLists,
    isLoading: loadingSearch,
    isFetching: fetchingSearch,
    refetch,
  } = useQuery({
    queryKey: ['search', 'word'],
    queryFn: (query) =>
      fetchWordBySearch(
        pagination.page,
        pagination.limit,
        querySearch.search.replace('?', '')
      ),
    enabled: false,
  });

  const {
    data: likeLists,
    isLoading: loadingLike,
    isFetching: fetchingLike,
  } = useQuery({
    queryKey: ['like'],
    queryFn: () => fetchLikesByUser(user?.user?.username),
  });

  //  Maping data
  const querySearchKeyword = useMemo((): string => {
    if (searchParams.get('value')) {
      return searchParams.get('value') || '';
    } else if (searchParams.get('hanji')) {
      return searchParams.get('hanji') || '';
    } else if (searchParams.get('roman')) {
      return searchParams.get('roman') || '';
    } else if (searchParams.get('english')) {
      return searchParams.get('english') || '';
    } else if (searchParams.get('chinese')) {
      return searchParams.get('chinese') || '';
    } else if (searchParams.get('malay')) {
      return searchParams.get('malay') || '';
    } else {
      return '';
    }
  }, [searchParams]);

  const wordListCount = useMemo((): number => {
    if (querySearchKeyword) {
      return wordLists?.totalResults ?? 0;
    } else {
      return 0;
    }
  }, [wordLists, querySearchKeyword]);

  const dataWordListsMap = useMemo((): TSelectedWord[] => {
    if (querySearchKeyword === '') {
      return [];
    }
    if (wordLists?.results) {
      const dataLikes = likeLists?.results || [];
      const dataWords = wordLists.results;

      const mapingWordWithLikesByUser = dataWords.map((word) => {
        const matchingWordLike = dataLikes.some(
          (like) => like.word._id === word.wordId && like.isLike
        );
        const matchingWordDislike = dataLikes.some(
          (like) => like.word._id === word.wordId && !like.isLike
        );
        if (matchingWordLike) {
          return {
            ...word,
            isLike: true,
          };
        } else if (matchingWordDislike) {
          return { ...word, isLike: false };
        } else {
          return word;
        }
      });
      setInitialLikes(mapingWordWithLikesByUser);
      return mapingWordWithLikesByUser;
    } else {
      return [];
    }
  }, [wordLists, likeLists, querySearchKeyword]);

  const exampleHanjiMap = useMemo((): string[] => {
    // const data = [selectedWord?.meaning?.[0].example?.[0].exampleHanji];
    const data = selectedWord?.meaning
      ?.filter((word) => word?.example)
      .map((word) =>
        word?.example
          ?.filter((example) => example?.exampleHanji)
          .map((example) => example?.exampleHanji)
      );
    return data?.flat() ?? [];
  }, [selectedWord]);

  const exampleRomanMap = useMemo((): string[] => {
    // const data = [selectedWord?.meaning?.[0].example?.[0].exampleRoman];
    const data = selectedWord?.meaning
      ?.filter((word) => word?.example)
      .map((word) =>
        word?.example
          ?.filter((example) => example?.exampleRoman)
          .map((example) => example?.exampleRoman)
      );
    return data?.flat() ?? [];
  }, [selectedWord]);

  const exampleChineseMap = useMemo((): string[] => {
    // const data = [selectedWord?.meaning?.[0].example?.[0].exampleChinese];
    const data = selectedWord?.meaning
      ?.filter((word) => word?.example)
      .map(
        (word) =>
          word.example &&
          word?.example
            ?.filter((example) => example?.exampleChinese)
            .map((example) => example?.exampleChinese)
      );
    return data?.flat() ?? [];
  }, [selectedWord]);

  console.log('roman: ', exampleRomanMap);
  console.log('hanji: ', exampleHanjiMap);
  console.log('chinese: ', exampleChineseMap);

  // Handlerr

  const handleModal = useCallback(
    (type: keyof typeof modalOptions, value: boolean): void => {
      setModalOptions((prev) => ({ ...prev, [type]: value }));
    },
    []
  );

  const handlePlayPauseAudio = useCallback(
    (e: React.MouseEvent<HTMLSpanElement>) => {
      e.stopPropagation();
      if (audioRef.current) {
        if (isPlaying) {
          audioRef.current.pause();
          audioRef.current.currentTime = 0;
          setIsPlaying(false);
        } else {
          setIsLoading(true);
          audioRef.current
            .play()
            .then(() => {
              setIsPlaying(true);
              setIsLoading(false);
            })
            .catch((error) => {
              console.error('Error playing audio:', error);
              setIsLoading(false);
            });
        }
      }
    },
    [isPlaying]
  );

  const handleAudioEnded = useCallback(() => {
    setIsPlaying(false);
  }, []);

  // Handle Like
  const handleLike = useCallback(
    async (wordId: string) => {
      if (!user) {
        handleModal('isConfirmOpen', true);
        return;
      }
      if (likes[wordId]?.value === true) {
        return;
      }
      const payload = {
        userId: user?.user?.id,
        wordId,
        isLike: true,
      };
      await createLike(payload);
      setLike(wordId);
      setIslike(true);

      return;
    },
    [createLike, setIslike, likes, user, setLike, handleModal]
  );
  const handleDislike = useCallback(
    async (wordId: string) => {
      if (!user) {
        handleModal('isConfirmOpen', true);
        return;
      }
      if (likes[wordId]?.value === false) return;
      const payload = {
        userId: user?.user?.id,
        wordId,
        isLike: false,
      };
      await createLike(payload);
      setDislike(wordId);
      setIslike(false);

      return;
    },
    [createLike, handleModal, setIslike, user, setDislike, likes]
  );

  useEffect(() => {
    if (searchParams.get('type')) {
      // Basic Search Config
      setSearchType('basic');
      if (querySearchKeyword) {
        setValueBasic('value', querySearchKeyword);
        setValueBasic(
          'type',
          isChinese(querySearchKeyword) ? 'chinese' : 'english'
        );
      }
    } else {
      // Advance Search Config

      if (!querySearch.search) {
        setSearchType('basic');
      } else {
        setSearchType('advance');
      }
      setValueAdvance('searchType', searchParams.get('searchType') ?? 'fuzzy');
      setValueAdvance('hanji', searchParams.get('hanji') || '');
      setValueAdvance('roman', searchParams.get('roman') || '');
      setValueAdvance('english', searchParams.get('english') || '');
      setValueAdvance('chinese', searchParams.get('chinese') || '');
      setValueAdvance('malay', searchParams.get('malay') || '');
    }
    if (querySearchKeyword !== '') {
      refetch();
    }
  }, [
    querySearch.search,
    refetch,
    setValueBasic,
    querySearchKeyword,
    pagination,
    searchParams,
    setValueAdvance,
  ]);

  const onSubmitBasic: SubmitHandler<ISearch['basic']> = (data) => {
    setSelectedWord(null);

    const payloadSearch = {
      value: data.value,
      type: data.type ?? '',
    };
    setPagination({
      ...pagination,
      page: 1,
    });
    navigate(`/search?${parsingObjToQuery(payloadSearch)}`);
  };

  const onSubmitAdvance: SubmitHandler<ISearch['advance']> = useCallback(
    (form) => {
      const params = parsingObjToQuery(form);
      setPagination({
        ...pagination,
        page: 1,
      });
      navigate(`?${params}`);
      refetch();
    },
    [navigate, parsingObjToQuery, refetch, pagination]
  );

  const handleContribution = () => {
    if (authContext?.isAuthenticated()) {
      setIsSuccessModalVisible(true);
    } else {
      setIsLoginModalVisible(true);
    }
  };

  const handleSuccessModalClose = () => {
    setIsSuccessModalVisible(false);
  };

  const handleLoginModalClose = () => {
    setIsLoginModalVisible(false);
  };
  const handlePagination = useCallback(
    (page: number, pageSize: number) => {
      setPagination({ ...pagination, page });
    },
    [pagination]
  );

  // Update the form's resolver when the language changes
  useEffect(() => {
    controlBasic._reset();
  }, [i18n.language, controlBasic]);

  return (
    <Layout>
      <HokkienDictionaryHeader />
      <Content
        style={{
          padding: '0 30px',
          maxWidth: 1100,
          margin: '0 auto',
          width: '100%',
        }}
      >
        <div style={{ height: 40 }} />

        {/* Basic Search */}
        {searchType === 'basic' ? (
          <BasicSearch
            control={controlBasic}
            setValue={setValueBasic}
            formState={formState}
            onSubmit={handleSubmitBasic(onSubmitBasic)}
          />
        ) : (
          // End of Basic Search */}

          // Advance Search */}
          <AdvanceSearch
            control={controlAdvance}
            setValue={setValueAdvance}
            reset={resetAdvance}
            onSubmit={handleSubmitAdvance(onSubmitAdvance)}
          />
        )}
        {/* End of Advance Search */}

        <div style={{ height: 16 }} />
        <Text
          style={{ color: '#98A2B3' }}
          label={`${t('searchResults')}: ${wordListCount}`}
        />

        {/* Word Selected */}
        {selectedWord ? (
          <Flex
            style={{
              padding: '24px',
              border: '.5px solid #D0D5DD',
              background: '#FFF',
              borderRadius: '10px',
              marginBottom: '24px',
              cursor: 'pointer',
            }}
            onClick={() => {
              navigate(`/search/word/${selectedWord?.wordId}`);
            }}
          >
            <Row
              gutter={[12, 24]}
              justify='space-between'
              style={{ width: '100%' }}
            >
              <Col>
                <Text
                  fontSize={24}
                  fontWeight={600}
                  label={selectedWord?.hanji}
                />
                <Flex align='center' gap={10}>
                  <Text
                    fontSize={16}
                    style={{ color: '#667085' }}
                    label={selectedWord?.roman}
                  />
                  {isLoading ? (
                    <LoadingOutlined
                      style={{ color: '#7E5435', fontSize: 16 }}
                    />
                  ) : (
                    <Tooltip
                      title={
                        !selectedWord?.recordURL
                          ? t('audioNotFound')
                          : !validAudioURL
                            ? t('invalidAudioSource')
                            : isPlaying
                              ? t('stop')
                              : t('play')
                      }
                    >
                      <span
                        onClick={
                          !selectedWord?.recordURL
                            ? undefined
                            : handlePlayPauseAudio
                        }
                        style={{
                          cursor:
                            !selectedWord?.recordURL || !validAudioURL
                              ? 'not-allowed'
                              : 'pointer',
                        }}
                      >
                        {isPlaying ? (
                          <StopIcon
                            style={{ color: '#7E5435', fontSize: 16 }}
                          />
                        ) : !validAudioURL ? (
                          <SoundFilled
                            style={{ color: '#d9d9d9', fontSize: 16 }}
                          />
                        ) : (
                          <SoundOutlined
                            style={{ color: '#7E5435', fontSize: 16 }}
                          />
                        )}
                      </span>
                    </Tooltip>
                  )}
                </Flex>

                <Text
                  label={selectedWord?.dictionary?.name}
                  style={{
                    overflow: 'hidden',
                    textWrap: 'nowrap',
                    textOverflow: 'ellipsis',
                  }}
                />
              </Col>
              <Col>
                <Flex
                  vertical
                  align={screens.lg ? 'end' : 'start'}
                  justify='end'
                  gap={5}
                  style={{
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    textWrap: 'nowrap',
                  }}
                >
                  <Text
                    fontSize={18}
                    label={exampleHanjiMap?.[0]}
                    style={{
                      overflow: 'hidden',
                      textWrap: 'nowrap',
                      textOverflow: 'ellipsis',
                    }}
                  />
                  <Text fontSize={18} label={exampleRomanMap?.[0]} style={{}} />
                  <Text
                    fontSize={18}
                    label={exampleChineseMap?.[0]}
                    style={{}}
                  />
                  <Flex gap={10} align='end'>
                    <Button
                      onClick={(e) => {
                        e.stopPropagation();
                        handleLike(selectedWord?.wordId);
                      }}
                      type={
                        likes[selectedWord.wordId]?.value
                          ? 'primary'
                          : undefined
                      }
                      icon={<LikeOutlined />}
                    >
                      {t('like')}
                    </Button>
                    <Button
                      onClick={(e) => {
                        e.stopPropagation();
                        handleDislike(selectedWord?.wordId);
                      }}
                      type={
                        likes[selectedWord.wordId]?.value === false
                          ? 'primary'
                          : undefined
                      }
                      icon={<DislikeOutlined />}
                    >
                      {t('dislike')}
                    </Button>
                  </Flex>
                </Flex>
              </Col>
            </Row>
            <audio
              ref={audioRef}
              src={selectedWord?.recordURL}
              onEnded={handleAudioEnded}
              onError={(e) => setValidAudioURL(false)}
              style={{ display: 'none' }}
            />
          </Flex>
        ) : (
          ''
        )}

        {/* End Word Selected */}

        <Row gutter={[16, 16]}>
          {loadingSearch || fetchingSearch ? (
            [...Array(3)].map((_, index) => (
              <Col
                xs={24}
                sm={12}
                md={8}
                key={index}
                style={{ background: '#FFF', borderRadius: 8 }}
              >
                <Skeleton active paragraph={{ rows: 4 }} />
              </Col>
            ))
          ) : dataWordListsMap.length > 0 ? (
            <>
              {dataWordListsMap.map((result, index) => (
                <Col
                  onClick={() => {
                    setSelectedWord(result);
                    setIslike(result?.isLike);
                  }}
                  key={index}
                  span={screens.md ? 8 : 24}
                >
                  <HokkienWordBox data={result} />
                </Col>
              ))}
              <Col span={screens.md ? 8 : 24}>
                <ContributeWordBox />
              </Col>
            </>
          ) : (
            <div
              style={{
                flexDirection: 'column',
                display: 'flex',
                alignItems: 'center',
                flex: 1,
              }}
            >
              {querySearch.search && (
                <>
                  {' '}
                  <SearchNotFoundIcon />
                  <div style={{ height: 16 }} />
                  <Text label={t('noSearchResults')} style={{ fontSize: 16 }} />
                  <Text
                    style={{ color: '#667085', fontSize: 14 }}
                    label={t('howDoYouUseInHokkien', {
                      word: getValuesBasic('value'),
                    })}
                  />
                </>
              )}
              <div style={{ height: 32 }} />
              <ContributeWordBox />
            </div>
          )}
          {/* Pagination */}
          {querySearch.search && (
            <Pagination
              total={wordLists?.totalResults}
              showSizeChanger={false}
              defaultPageSize={11}
              totalPage={wordLists?.totalPages}
              onChange={handlePagination}
            />
          )}
        </Row>

        <div style={{ height: 80 }} />
        <ContributeSuccessModal
          open={isSuccessModalVisible}
          onClose={handleSuccessModalClose}
        />
        <LoginRequiredModal
          open={isLoginModalVisible}
          onClose={handleLoginModalClose}
        />
      </Content>
      <HokkienDictionaryFooter />
      <ModalConfirm
        open={modalOptions.isConfirmOpen}
        title={t('modalConfirmAuthorizationAccess')}
        footer={[
          <Button key='back' onClick={() => navigate('/register')}>
            {t('register')}
          </Button>,
          <Button
            key='submit'
            type='primary'
            onClick={() => navigate('/login')}
          >
            {t('login')}
          </Button>,
        ]}
        onCancel={() => handleModal('isConfirmOpen', false)}
        onOk={() => navigate('/login')}
      >
        {t('modalConfirmAuthorizationAccessContent')}
      </ModalConfirm>
    </Layout>
  );
};

export default SearchResults;
