import React, { useState, useEffect, useContext, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import {
  Typography,
  Col,
  Row,
  Avatar,
  Card,
  Button,
  Layout,
  Input,
  message,
  Upload,
  Space,
  Flex,
  Radio,
  Modal,
  Skeleton,
} from 'antd';
import {
  CloseOutlined,
  EditOutlined,
  InfoCircleFilled,
  SaveOutlined,
  UploadOutlined,
} from '@ant-design/icons';
import { useMutation, useQuery } from '@tanstack/react-query';
import { useForm, Controller } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import HokkienDictionaryHeader from '../../components/layout/HokkienDictionaryHeader';
import HokkienDictionaryFooter from '../../components/layout/HokkienDictionaryFooter';
import { useProfileAPI } from './api/ProfileAPI';
import { ProfileFormData, profileSchema } from './type/ProfileType';
import { AuthContext } from '../../context/AuthContext';
import ModalConfirm from '../../components/base/ModalConfirm';

const { Content } = Layout;
const { Text, Title } = Typography;
const { confirm } = Modal;

const Profile: React.FC = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [isEditing, setIsEditing] = useState(false);
  const { fetchProfile, updateProfile } = useProfileAPI();
  const [imageUrl, setImageUrl] = useState<string | null>(null);
  const [fileList, setFileList] = useState<any[]>([]);
  const [modalOptions, setModalOptions] = useState({
    isModalConfirm: false,
  });

  const authContext = useContext(AuthContext);

  const {
    data: profile,
    isLoading,
    isError,
    refetch,
  } = useQuery({
    queryKey: ['profile'],
    queryFn: fetchProfile,
  });

  const {
    control,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm<ProfileFormData>({
    resolver: zodResolver(profileSchema),
    defaultValues: {
      username: '',
      email: '',
      password: '',
      isShowLeaderBoard: false,
    },
  });

  useEffect(() => {
    if (profile) {
      setValue('username', profile.username);
      setValue('email', profile.email);
      setValue('password', profile.password);
      setValue('isShowLeaderBoard', profile.isShowLeaderBoard);
      setImageUrl(profile.profilePicture || null);
    }
  }, [profile, setValue]);

  const updateProfileMutation = useMutation({
    mutationKey: ['update-profile'],
    mutationFn: updateProfile,
    onSuccess: async (data) => {
      message.success(t('profileUpdatedSuccessfully'));
      setIsEditing(false);
      await refetch();
      authContext?.updateUser({
        user: data,
        tokens: authContext?.user?.tokens,
      } as any);
    },
    onError: () => {
      message.error(t('failedToUpdateProfile'));
    },
  });

  const handleModal = useCallback(
    (type: keyof typeof modalOptions, value: boolean): void => {
      setModalOptions((prev) => ({ ...prev, [type]: value }));
    },
    []
  );

  const onSubmit = (data: ProfileFormData) => {
    const formData = new FormData();
    Object.entries(data).forEach(([key, value]) => {
      if (value !== undefined && key !== 'image') {
        formData.append(key, String(value));
      }
    });
    if (fileList.length > 0) {
      formData.append('image', fileList[0].originFileObj);
    }
    updateProfileMutation.mutate(formData);
  };

  const handleEdit = () => {
    if (isEditing) {
      handleModal('isModalConfirm', true);
    } else {
      setIsEditing(true);
    }
  };

  const handleFileChange = (info: any) => {
    const { fileList } = info;

    if (fileList.length > 0) {
      const file = fileList[0].originFileObj;

      if (file.size > 50e6) {
        message.error(t('maxFileUpload'));
        setImageUrl(null);
        return;
      }
      const reader = new FileReader();
      reader.onload = (e) => {
        if (e.target?.result) {
          setImageUrl(e.target.result as string);
        }
      };
      reader.readAsDataURL(file);
      setFileList(fileList);
    } else {
      setImageUrl(profile?.profilePicture || null);
    }
  };

  if (isError) {
    return <div>{t('errorLoadingProfile')}</div>;
  }

  return (
    <Layout>
      <HokkienDictionaryHeader />
      <Content style={{ padding: '20px' }}>
        <Card
          title={<Title level={4}>{t('myAccountAndSettings')}</Title>}
          extra={
            <Space>
              {isEditing && (
                <Button
                  icon={<CloseOutlined />}
                  onClick={() => setIsEditing(false)}
                  loading={updateProfileMutation.isPending}
                >
                  {t('cancel')}
                </Button>
              )}
              <Button
                type='primary'
                icon={isEditing ? <SaveOutlined /> : <EditOutlined />}
                onClick={handleEdit}
                loading={updateProfileMutation.isPending}
              >
                {isEditing ? t('save') : t('edit')}
              </Button>
            </Space>
          }
          style={{ maxWidth: '600px', margin: 'auto' }}
        >
          <form onSubmit={handleSubmit(onSubmit)}>
            <Row gutter={[16, 16]} align='middle'>
              <Col xs={24} md={8}>
                <Flex vertical>
                  <Text style={{ fontSize: 16, color: '#101828' }}>
                    {t('profilePicture')}
                  </Text>
                  <Text style={{ color: '#98A2B3' }}>
                    {t('profilePictureDescription')}
                  </Text>
                </Flex>
              </Col>
              <Col xs={24} md={16}>
                {isLoading ? (
                  <Skeleton.Avatar
                    active
                    style={{ width: '64px', height: '64px' }}
                  />
                ) : (
                  <Avatar size={64} src={imageUrl} />
                )}
                {isEditing && (
                  <Upload
                    accept='image/*'
                    fileList={fileList}
                    onChange={handleFileChange}
                    beforeUpload={() => false}
                  >
                    <Button
                      icon={<UploadOutlined />}
                      style={{ marginLeft: '10px' }}
                    >
                      {t('uploadNewPicture')}
                    </Button>
                  </Upload>
                )}
              </Col>
            </Row>
            <Row gutter={[16, 16]} align={'middle'}>
              <Col md={8} xs={24}>
                <Flex vertical>
                  <Text style={{ fontSize: 16, color: '#101828' }}>
                    {t('displayName')}
                  </Text>
                  <Text style={{ color: '#98A2B3' }}>
                    {t('displayNameDescription')}
                  </Text>
                </Flex>
              </Col>
              <Col xs={24} md={16}>
                {isLoading ? (
                  <Skeleton.Input active />
                ) : (
                  <Controller
                    name='username'
                    control={control}
                    render={({ field }) => (
                      <Input {...field} disabled={!isEditing} />
                    )}
                  />
                )}
                {errors.username && (
                  <Text type='danger'>{errors.username.message}</Text>
                )}
              </Col>
            </Row>
            <Row gutter={[16, 16]} align='middle'>
              <Col md={8} xs={24}>
                <Flex vertical>
                  <Text style={{ fontSize: 16, color: '#101828' }}>
                    {t('email')}
                  </Text>
                  <Text style={{ color: '#98A2B3' }}>
                    {t('emailDescription')}
                  </Text>
                </Flex>
              </Col>
              <Col md={16} xs={24}>
                {isLoading ? (
                  <Skeleton.Input active />
                ) : (
                  <Controller
                    name='email'
                    control={control}
                    render={({ field }) => (
                      <Input
                        {...field}
                        disabled
                        aria-disabled={true}
                        title='You cannot change your email'
                      />
                    )}
                  />
                )}
                {errors.email && (
                  <Text type='danger'>{errors.email.message}</Text>
                )}
              </Col>
            </Row>
            <Row gutter={[16, 16]} align={'middle'}>
              <Col md={8} xs={24}>
                <Flex vertical>
                  <Text style={{ fontSize: 16, color: '#101828' }}>
                    {t('password')}
                  </Text>
                  <Text style={{ color: '#98A2B3' }}>
                    {t('passwordDescription')}
                  </Text>
                </Flex>
              </Col>
              <Col md={16} xs={24}>
                {isLoading ? (
                  <Skeleton.Input active />
                ) : (
                  <Controller
                    name='password'
                    control={control}
                    rules={{ required: false }}
                    render={({ field }) => (
                      <Input
                        {...field}
                        required={false}
                        disabled={!isEditing}
                        type='password'
                      />
                    )}
                  />
                )}
                {errors.password && (
                  <Text type='danger'>{errors.password.message}</Text>
                )}
              </Col>
            </Row>
            <Row gutter={[16, 16]} align='middle'>
              <Col md={8} xs={24}>
                <Flex vertical>
                  <Text style={{ fontSize: 16, color: '#101828' }}>
                    {t('displayOnLeaderboard')}
                  </Text>
                  <Text style={{ color: '#98A2B3' }}>
                    {t('showLeaderboardDescription')}
                  </Text>
                </Flex>
              </Col>
              <Col md={16} xs={24}>
                {isLoading ? (
                  <Skeleton.Input active />
                ) : (
                  <Controller
                    name='isShowLeaderBoard'
                    control={control}
                    render={({ field: { value, onChange } }) => (
                      <Radio.Group
                        value={value}
                        disabled={!isEditing}
                        onChange={onChange}
                      >
                        <Radio value={true}>
                          {t('showLeaderboardOptionYes')}
                        </Radio>
                        <Radio value={false}>
                          {t('showLeaderboardOptionNo')}
                        </Radio>
                      </Radio.Group>
                    )}
                  />
                )}
              </Col>
            </Row>
          </form>
        </Card>
      </Content>
      <HokkienDictionaryFooter />
      <ModalConfirm
        open={modalOptions.isModalConfirm}
        title={t('editProfileConfirmTitle')}
        onOk={() => {
          handleSubmit(onSubmit)();
          handleModal('isModalConfirm', false);
        }}
        onCancel={() => handleModal('isModalConfirm', false)}
      >
        {t('editProfileConfirmBody')}
      </ModalConfirm>
    </Layout>
  );
};

export default Profile;
