import { useMutation } from '@tanstack/react-query';
import React, { forwardRef, useCallback, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useMediaQuery } from 'react-responsive';
import { useNavigate, useSearchParams } from 'react-router-dom';
import backgroundSvg from '../../assets/svg/login-background.svg';
import { logoUrl } from '../../utils/constant';
import { Button, Flex, Form, Input, message, Modal } from 'antd';
import Text from 'antd/es/typography/Text';
import { ResetPasswordModel, ResetPasswordSchema } from './types/ResetPassword';
import { zodResolver } from '@hookform/resolvers/zod';
import { resetPassword } from './api/ResetPassword';
import { CheckCircleFilled } from '@ant-design/icons';
import ModalConfirm from '../../components/base/ModalConfirm';

const ResetPassword = forwardRef(() => {
  const [modalOptions, setModalOptions] = useState({
    isConfirmOpen: false,
  });
  const { t } = useTranslation();
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const isMobile = useMediaQuery({ maxWidth: 767 });

  const {
    handleSubmit,
    control,
    reset,
    formState: { errors },
  } = useForm<ResetPasswordModel>({
    resolver: zodResolver(ResetPasswordSchema),
  });

  const { mutate: forgotPasswordMutate, isPending } = useMutation({
    mutationFn: (item: { password: string }) =>
      resetPassword(item, searchParams.get('token') ?? ''),
  });

  const handleModal = useCallback(
    (type: keyof typeof modalOptions, value: boolean): void => {
      setModalOptions((prev) => ({ ...prev, [type]: value }));
    },
    []
  );
  const onSubmit = (data: ResetPasswordModel) => {
    forgotPasswordMutate(data, {
      onSuccess: () => {
        handleModal('isConfirmOpen', true);
      },
      onError: (error) => {
        reset();
        handleForgotPasswordError(error);
      },
    });
  };

  const handleForgotPasswordError = (error: any) => {
    if (error.response) {
      message.error(error.response.data.message || error.response.data.error);
    } else if (error.request) {
      message.error(t('networkError'));
    } else {
      message.error(t('forgotPasswordError'));
    }
    console.error('Forgot password error: ', error);
  };

  return (
    <main
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        background: `url(${backgroundSvg}) no-repeat center center `,
        backgroundSize: 'cover',
        height: '100vh',
        padding: '0 20px',
      }}
    >
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <img src={logoUrl} alt='logo' style={{ width: '80px' }} />
        <div style={{ height: '20px' }} />
        <div
          style={{
            padding: '40px',
            margin: '0 16px',
            borderRadius: '8px',
            boxShadow:
              '0px 4px 8px -4px rgba(255, 36, 66, 0.03), 0px 20px 30px -4px rgba(255, 36, 66, 0.08)',
            background: 'white',
            width: isMobile ? 'auto' : '560px',
            boxSizing: 'border-box',
          }}
        >
          <div />
          <Text style={{ color: '#333333', fontSize: 20, fontWeight: 600 }}>
            {t('resetYourPassword')}
          </Text>
          <div style={{ height: '24px' }} />
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <Form onFinish={handleSubmit(onSubmit)} layout='vertical'>
                <Form.Item
                  label={t('password')}
                  name='password'
                  style={{ color: '#344054' }}
                  validateStatus={errors.password ? 'error' : ''}
                  help={errors.password?.message}
                >
                  <Controller
                    name='password'
                    control={control}
                    defaultValue=''
                    render={({ field }) => (
                      <Input.Password
                        {...field}
                        type='password'
                        autoComplete='password'
                        placeholder={t('password')}
                      />
                    )}
                  />
                </Form.Item>
                <div style={{ height: '16px' }} />
                <Form.Item>
                  <div style={{ display: 'flex', flexDirection: 'column' }}>
                    <Button
                      type='primary'
                      htmlType='submit'
                      block
                      loading={isPending}
                      style={{ minHeight: 44 }}
                    >
                      {t('resetPassword')}
                    </Button>
                  </div>
                </Form.Item>
              </Form>
            </div>
          </div>
        </div>
      </div>
      <ModalConfirm
        open={modalOptions.isConfirmOpen}
        title={t('resetPassword')}
        footer={[
          <Button
            key='submit'
            type='primary'
            onClick={(e) => {
              e.stopPropagation();
              navigate('/login');
            }}
          >
            {t('ok')}
          </Button>,
        ]}
        onCancel={(e) => {}}
      >
        {t('resetPasswordSuccessModalContent')}
      </ModalConfirm>
    </main>
  );
});

ResetPassword.displayName = 'ResetPassword';

export default ResetPassword;
