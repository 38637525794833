import { Flex, Skeleton } from 'antd';
import React from 'react';

const SkeletonBar = ({ height, width }: { width: number; height: number }) => {
  return (
    <Flex vertical gap={10}>
      <Skeleton.Avatar active={true} shape={'circle'} size={'large'} />
      <Skeleton.Input size='small' active={true} />
      <Skeleton.Node
        active={true}
        style={{
          height: `${height}px`,
          width: `${width}px`,
          borderRadius: '20px 20px 0 0',
        }}
      >
        <div style={{ height: `${height}px`, width: `${width}px` }} />
      </Skeleton.Node>
    </Flex>
  );
};

export default SkeletonBar;
