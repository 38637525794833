import React from "react";
import Marquee from "react-fast-marquee";
import { useTranslation } from "react-i18next";
import megaphone from "../../assets/svg/megaphone.svg";

interface NewsTickerProps {
  announcements: string[];
}

const NewsTicker: React.FC<NewsTickerProps> = ({ announcements }) => {
  const { t } = useTranslation();

  const newsTickerStyle: React.CSSProperties = {
    width: "100%",
    backgroundColor: "#F2EEEB",
    overflow: "hidden",
    position: "relative",
    whiteSpace: "nowrap",
    borderRadius: "0.5rem",
    padding: "1rem",
  };

  const tickerItemStyle: React.CSSProperties = {
    // display: "inline-block flex",
    padding: "0 2rem",
    alignItems: "center",
    justifyContent: "start",
  };

  const iconStyle: React.CSSProperties = {
    marginRight: "10px",
    color: "#7E5435",
  };

  const alertTextStyle: React.CSSProperties = {
    marginRight: "6px",
    fontWeight: "400",
    color: "#7E5435",
  };

  return (
    <div style={newsTickerStyle}>
      <Marquee speed={30} gradient={false}>
        {announcements.map((announcement, index) => (
          <div style={tickerItemStyle} key={index}>
            <span style={alertTextStyle}>{t("announcement")}</span>
            <img src={megaphone} alt='megaphone' style={iconStyle} />
            {announcement}
          </div>
        ))}
      </Marquee>
    </div>
  );
};

export default NewsTicker;
