import { Button, Modal } from 'antd';
import React, { memo } from 'react';
import { useTranslation } from 'react-i18next';
import { IModalConfirmProps } from './interfaces';

const ModalConfirm = memo(
  ({ open, onOk, onCancel, title, children, footer }: IModalConfirmProps) => {
    const { t } = useTranslation();
    return (
      <Modal
        title={title}
        open={open}
        onOk={onOk}
        onCancel={onCancel}
        footer={
          footer
            ? footer
            : [
                <Button key='back' onClick={onCancel}>
                  {t('cancel')}
                </Button>,
                <Button key='submit' type='primary' onClick={onOk}>
                  {t('ok')}
                </Button>,
              ]
        }
      >
        {children}
      </Modal>
    );
  }
);

ModalConfirm.displayName = 'ModalConfirm';
export default ModalConfirm;
