import React from 'react';
import type { PaginationProps } from 'antd';
import { Grid, Pagination as Paginate, Space } from 'antd';
import Text from '../base/Text';
import { ArrowLeftOutlined, ArrowRightOutlined } from '@ant-design/icons';

interface IPaginationProps extends PaginationProps {
  totalPage?: number;
}

const { useBreakpoint } = Grid;
const Pagination = ({ total, totalPage, ...props }: IPaginationProps) => {
  const screens = useBreakpoint();
  const itemRender: PaginationProps['itemRender'] = (
    page,
    type,
    originalElement
  ) => {
    if (type === 'prev') {
      return (
        <Space>
          <ArrowLeftOutlined
            style={{ color: page === 0 ? 'rgba(0,0,0,.2)' : '#7e5435' }}
          />
          <Text
            label={screens.md ? 'Previous' : ''}
            style={{ color: page === 0 ? 'rgba(0,0,0,.2)' : '#7e5435' }}
          />
        </Space>
      );
    }
    if (type === 'next') {
      return (
        <Space>
          <Text
            label={screens.md ? 'Next' : ''}
            style={{
              color: page === totalPage ? 'rgba(0,0,0,.2)' : '#7e5435',
            }}
          />
          <ArrowRightOutlined
            style={{
              color: page === totalPage ? 'rgba(0,0,0,.2)' : '#7e5435',
            }}
          />
        </Space>
      );
    }
    return originalElement;
  };
  return (
    <Paginate
      {...props}
      total={total}
      itemRender={itemRender}
      responsive
      style={{
        width: '100vw',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    />
  );
};

export default Pagination;
