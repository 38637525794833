import { z } from 'zod';

export const profileSchema = z.object({
  username: z
    .string()
    .min(1, 'Name is required')
    .max(20, 'Name need to be less than 20 chars'),
  email: z.string().email('Invalid email address'),
  password: z.optional(z.string()),
  isShowLeaderBoard: z.boolean(),
  image: z.instanceof(File).optional(),
});

export type ProfileFormData = z.infer<typeof profileSchema>;

export interface ProfileType {
  id?: string;
  username: string;
  email: string;
  password: string;
  role: string;
  profilePicture?: string;
  isShowLeaderBoard: boolean;
}
