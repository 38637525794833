import { SignupModel } from '../../pages/Signup/type/signupType';
import axiosInstance from '../axiosInstance';
import { LoginModel } from '../type/loginType';

export const signup = async (data: SignupModel) => {
  const response = await axiosInstance.post('/auth/register', data);
  return response.data;
};

export const login = async (data: LoginModel) => {
  const response = await axiosInstance.post('/auth/login', {
    email: data.email,
    password: data.password,
  });
  return response.data;
};

export const logout = async () => {
  await axiosInstance.post('/auth/logout');
};

export const sendVerificationEmail = async (token: string) => {
  const response = await axiosInstance.post(
    `/auth/send-verification-email`,
    undefined,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );
  return response.data;
};

export const verifyEmail = async (token: string) => {
  const response = await axiosInstance.post(
    `/auth/verify-email?token=${token}`,
    undefined,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );
  return response.data;
};
