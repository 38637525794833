import React, { useContext, useMemo, useState } from 'react';
import {
  Button,
  Drawer,
  Layout,
  Menu,
  Select,
  Typography,
  Avatar,
  Flex,
  Space,
} from 'antd';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { GlobalOutlined, MenuOutlined } from '@ant-design/icons';
import { useMediaQuery } from 'react-responsive';
import { ReactComponent as Logo } from '../../assets/svg/logo.svg';
import { AuthContext } from '../../context/AuthContext'; // Adjust the path
import i18n from '../../i18n';
import Cookies from 'js-cookie';
import { languageOptions } from '../../constant/options/language';
import { menuOptions } from '../../constant/options/menu';
import { logoUrl } from '../../utils/constant';

const { Text } = Typography;
const { Header } = Layout;

const HokkienDictionaryHeader = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const isMobile = useMediaQuery({ query: '(max-width: 768px)' });
  const isDesktop = useMediaQuery({ query: '(max-width: 1024px)' });
  const [drawerVisible, setDrawerVisible] = useState(false);
  const authContext = useContext(AuthContext);

  // Temporarily get user info from authContext or localStorage
  const user = authContext?.user || JSON.parse(Cookies.get('user') || 'null');

  const handleLogout = () => {
    authContext?.logout();
  };

  const defaultLanguageSelected = useMemo(() => {
    const defaultLanguage = languageOptions.find((item) =>
      i18n.language.includes(item.value)
    )?.value;
    return defaultLanguage;
  }, []);

  return (
    <Header
      style={{
        backgroundColor: '#fff',
        display: 'flex',
        alignItems: 'center',
        padding: `0 ${isDesktop ? 0 : '175px'}`,
      }}
    >
      <div style={{ display: 'flex', alignItems: 'center', flex: 1 }}>
        <Link to='/' style={{ display: 'flex', alignItems: 'center' }}>
          {/* <Logo style={{ height: 48, width: 48 }} /> */}
          {/* https://hokkien-storage.s3.ap-southeast-1.amazonaws.com/default/logo.svg */}

          <img src={logoUrl} alt='logo' style={{ height: 48, width: 48 }} />
        </Link>
        <div style={{ width: 10 }} />
        {isMobile ? (
          <>
            <div style={{ flex: 1 }} />
            <Button
              type='primary'
              icon={<MenuOutlined />}
              onClick={() => setDrawerVisible(true)}
            />
            <Drawer
              placement='right'
              closable={true}
              onClose={() => setDrawerVisible(false)}
              open={drawerVisible}
            >
              <Menu mode='vertical'>
                <Menu.Item
                  key='1'
                  onClick={() => {
                    navigate('/leaderboard');
                    setDrawerVisible(false);
                  }}
                >
                  {t('contributionRankings')}
                </Menu.Item>
                <Menu.Item
                  key='2'
                  onClick={() => {
                    window.location.href =
                      'https://www.speakhokkien.org/ru-he-feng-xian';
                    setDrawerVisible(false);
                  }}
                >
                  {t('sponsorSupport')}
                </Menu.Item>
                {user ? (
                  <>
                    <Menu.Item
                      key='profile'
                      onClick={() => {
                        navigate('/profile');
                        setDrawerVisible(false);
                      }}
                    >
                      <Avatar src={user?.user?.imageURL} />
                      <Text style={{ marginLeft: 8 }}>
                        {user?.user?.username}
                      </Text>
                    </Menu.Item>
                    <Menu.Item
                      key='logout'
                      onClick={() => {
                        handleLogout();
                        setDrawerVisible(false);
                      }}
                    >
                      {t('signOut')}
                    </Menu.Item>
                  </>
                ) : (
                  <>
                    <Menu.Item
                      key='5'
                      onClick={() => {
                        navigate('/login');
                        setDrawerVisible(false);
                      }}
                    >
                      {t('login')}
                    </Menu.Item>
                    <Menu.Item
                      key='6'
                      onClick={() => {
                        navigate('/register');
                        setDrawerVisible(false);
                      }}
                    >
                      {t('register')}
                    </Menu.Item>
                  </>
                )}
                <div style={{ height: 10 }} />
                <Select
                  defaultValue={defaultLanguageSelected}
                  style={{ width: 120, margin: '0 16px' }}
                  onChange={(value) => {
                    i18n.changeLanguage(value);
                    setDrawerVisible(false);
                  }}
                  menuItemSelectedIcon={<GlobalOutlined />}
                >
                  {languageOptions.map((item) => (
                    <Select.Option key={item.value} value={item.value}>
                      {item.label}
                    </Select.Option>
                  ))}
                </Select>
              </Menu>
            </Drawer>
          </>
        ) : (
          <>
            <Space>
              {menuOptions.map((item) =>
                item.url.includes('https') ? (
                  <Text
                    style={{
                      cursor: 'pointer',
                      fontSize: '14px',
                      color: '#667085',
                      textWrap: 'nowrap',
                      padding: '8px 16px',
                    }}
                    onClick={() => {
                      window.open(item.url, '_blank');
                    }}
                  >
                    {t('sponsorSupport')}
                  </Text>
                ) : (
                  <Link
                    to={item.url}
                    style={{
                      fontSize: '14px',
                      color: location.pathname.includes(item.url)
                        ? '#FFF'
                        : '#667085',
                      background: location.pathname.includes(item.url)
                        ? '#7E5435'
                        : '',
                      padding: '8px 16px',
                      borderRadius: '8px',
                      textWrap: 'nowrap',
                    }}
                  >
                    {t(item.label)}
                  </Link>
                )
              )}
            </Space>
            <div style={{ flex: 1 }} />
            {user ? (
              <Flex align='center' gap={20}>
                <div
                  onClick={() => navigate('/profile')}
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    cursor: 'pointer',
                  }}
                >
                  <Avatar src={user?.user?.imageURL} />
                  <Text style={{ marginLeft: 8 }}>{user?.user?.username}</Text>
                </div>
                <Select
                  defaultValue={defaultLanguageSelected}
                  style={{ width: 120 }}
                  onChange={(value) => {
                    i18n.changeLanguage(value);
                  }}
                  menuItemSelectedIcon={<GlobalOutlined />}
                >
                  {languageOptions.map((item) => (
                    <Select.Option key={item.value} value={item.value}>
                      {item.label}
                    </Select.Option>
                  ))}
                </Select>
                <Button onClick={handleLogout}>{t('signOut')}</Button>
              </Flex>
            ) : (
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <Button
                  type='primary'
                  onClick={() => {
                    navigate('/login');
                  }}
                >
                  {t('login')}
                </Button>
                <div style={{ width: 10 }} />
                <Button
                  onClick={() => {
                    navigate('/register');
                  }}
                >
                  {t('register')}
                </Button>
                <div style={{ width: 10 }} />
                <Select
                  defaultValue={defaultLanguageSelected}
                  style={{ width: 120 }}
                  onChange={(value) => {
                    i18n.changeLanguage(value);
                  }}
                  menuItemSelectedIcon={<GlobalOutlined />}
                >
                  {languageOptions.map((item) => (
                    <Select.Option key={item.value} value={item.value}>
                      {item.label}
                    </Select.Option>
                  ))}
                </Select>
              </div>
            )}
          </>
        )}
      </div>
    </Header>
  );
};

export default HokkienDictionaryHeader;
