import axios from 'axios';
import Cookies from 'js-cookie';

const apiHost = process.env.REACT_APP_API_HOST;

const axiosInstance = axios.create({
  baseURL: `${apiHost}`,
});

// Request interceptor to add the auth token to headers
axiosInstance.interceptors.request.use((config) => {
  const token = Cookies.get('accessToken');
  if (token) {
    config.headers['Authorization'] = `Bearer ${token}`;
  }
  return config;
});

// Function to clear all cookies and local storage
const clearAllStoredData = () => {
  // Clear all cookies
  const cookies = Cookies.get();
  Object.keys(cookies).forEach((cookieName) => {
    Cookies.remove(cookieName);
  });

  // Clear local storage
  localStorage.clear();
};

axiosInstance.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response.status === 401) {
      // Clear all stored data
      clearAllStoredData();
    }
    return Promise.reject(error);
  }
);

export default axiosInstance;
