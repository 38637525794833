import { CloseOutlined, SearchOutlined } from '@ant-design/icons';
import { Button, Flex, Form, Input, Radio, Space, Typography } from 'antd';
import React, { forwardRef, memo, useCallback } from 'react';
import { Controller, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import InputText from '../../../components/base/InputText';
import { InitialFilter } from '../../../constant/home';
import { useNavigate } from 'react-router-dom';
import { useQueryRouteHandler } from '../../../utils/RouteHandlers';
import { convertTextToStrokeTone } from '../../../utils/convertToneToStroke';

const AdvanceSearchComponent = forwardRef(
  ({ control, setValue, reset, getValues, formState }: any) => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const { parsingObjToQuery } = useQueryRouteHandler();

    const watchHanji = useWatch({ control, name: 'hanji' });
    const watchRoman = useWatch({
      control,
      name: 'roman',
    });
    const watchEnglish = useWatch({ control, name: 'english' });
    const watchMalay = useWatch({ control, name: 'malay' });
    const watchChinese = useWatch({ control, name: 'chinese' });

    const handleClearForm = useCallback(() => {
      reset();
    }, [reset]);

    const handleSearch = useCallback(() => {
      // parsing payload to string
      const params = parsingObjToQuery(getValues());
      navigate(`search?${params}`);
    }, [navigate, parsingObjToQuery, getValues]);
    return (
      <div>
        <Space direction='vertical' size='large'>
          <Flex gap={10} wrap='wrap' align='center'>
            <Typography
              style={{
                fontWeight: '600',
              }}
            >
              {t('filteringMethod')}
            </Typography>

            <Controller
              control={control}
              name='searchType'
              render={({ field }) => (
                <Radio.Group {...field} value={field.value}>
                  <Radio value='exact'>{t('exactSearch')}</Radio>
                  <Radio value='fuzzy'>{t('fuzzySearch')}</Radio>
                </Radio.Group>
              )}
            />
          </Flex>
          <Flex vertical gap={15}>
            <Controller
              control={control}
              name='roman'
              render={({ field }) => (
                <InputText
                  {...field}
                  onChange={(e) => {
                    let value = e.target.value;
                    field.onChange(e);
                    const endOfCharacter =
                      e.target.value[e.target.value.length - 1];
                    if (/[0-9]/.test(endOfCharacter)) {
                      setTimeout(() => {
                        value = convertTextToStrokeTone(e.target.value);
                        setValue('roman', value);
                      }, 500);
                    } else {
                      setValue('roman', value);
                    }
                  }}
                  label={t('romanisedHokkien')}
                  placeholder={t('enterKeywords')}
                />
              )}
            />

            <Controller
              control={control}
              name='hanji'
              render={({ field }) => (
                <InputText
                  {...field}
                  label={t('chineseAndRomanisedHokkien')}
                  placeholder={t('enterKeywords')}
                />
              )}
            />

            <Controller
              control={control}
              name='english'
              render={({ field }) => (
                <InputText
                  {...field}
                  label={t('correspondingEnglish')}
                  placeholder={t('recommendFuzzySearch')}
                />
              )}
            />

            <Controller
              control={control}
              name='chinese'
              render={({ field }) => (
                <InputText
                  {...field}
                  label={t('correspondingChinese')}
                  placeholder={t('recommendFuzzySearch')}
                />
              )}
            />
            <Controller
              control={control}
              name='malay'
              render={({ field }) => (
                <InputText
                  {...field}
                  label={t('correspondingMalay')}
                  placeholder={t('recommendFuzzySearch')}
                />
              )}
            />
            <Flex gap={10} justify='end'>
              <Button icon={<CloseOutlined />} onClick={handleClearForm}>
                {t('clear')}
              </Button>
              <Button
                icon={<SearchOutlined />}
                type='primary'
                onClick={handleSearch}
                disabled={
                  !watchChinese &&
                  !watchEnglish &&
                  !watchHanji &&
                  !watchRoman &&
                  !watchMalay
                }
              >
                {t('search')}
              </Button>
            </Flex>
          </Flex>
        </Space>
      </div>
    );
  }
);

AdvanceSearchComponent.displayName = 'AdvanceSearchComponent';

export default AdvanceSearchComponent;
