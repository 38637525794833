import './styles/index.css';
import React from 'react';
import { ConfigProvider, Empty, theme } from 'antd';
import en_US from 'antd/locale/en_US';
import ReactDOM from 'react-dom/client';
import App from './App';
import reportWebVitals from './reportWebVitals';
import './i18n';
import { t } from 'i18next';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

root.render(
  <ConfigProvider
    locale={en_US}
    renderEmpty={() => (
      <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={t('noData')} />
    )}
    theme={{
      token: {
        colorPrimary: '#7E5435',
        fontFamily: 'GenSekiGothic',
      },
    }}
  >
    <App />
  </ConfigProvider>
);

reportWebVitals();
