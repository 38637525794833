import React from "react";
import { Modal, Button } from "antd";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";

interface LoginRequiredModalProps {
  open: boolean;
  onClose: () => void;
}

const LoginRequiredModal: React.FC<LoginRequiredModalProps> = ({
  open,
  onClose,
}) => {
  const navigate = useNavigate();

  const handleLogin = () => {
    navigate("/login");
  };

  const handleRegister = () => {
    navigate("/register");
  };

  return (
    <Modal open={open} onCancel={onClose} footer={null} centered>
      <div style={{ textAlign: "center" }}>
        <ExclamationCircleOutlined
          style={{ fontSize: "48px", color: "#faad14" }}
        />
        <div
          style={{ marginTop: "16px", fontSize: "18px", fontWeight: "bold" }}
        >
          前往登入。
        </div>
        <div style={{ marginTop: "8px" }}>貢獻新詞需要登入帳號喔~</div>
        <div style={{ marginTop: "24px" }}>
          <Button
            type='primary'
            style={{ marginRight: "8px" }}
            onClick={handleRegister}
          >
            註冊
          </Button>
          <Button onClick={handleLogin}>登入</Button>
        </div>
      </div>
    </Modal>
  );
};

export default LoginRequiredModal;
