import React, { useCallback, useContext } from 'react';
import { Navigate, Outlet, useLocation } from 'react-router-dom';
import { AuthContext } from '../context/AuthContext';

export const PrivateRoute: React.FC = () => {
  const auth = useContext(AuthContext);
  const location = useLocation();

  if (!auth) {
    throw new Error('AuthContext not found');
  }

  if (!auth.isAuthenticated()) {
    // Redirect to login page, but save the current location
    return <Navigate to='/login' state={{ from: location }} replace />;
  }

  return <Outlet />;
};

interface PublicRouteProps {
  restrictedWhenAuth?: boolean;
}

export const PublicRoute: React.FC<PublicRouteProps> = ({
  restrictedWhenAuth = false,
}) => {
  const auth = useContext(AuthContext);
  const location = useLocation();

  if (!auth) {
    throw new Error('AuthContext not found');
  }

  if (auth.isAuthenticated() && restrictedWhenAuth) {
    // Redirect to home page or last intended destination
    const intendedDestination = location.state?.from?.pathname || '/';
    return <Navigate to={intendedDestination} replace />;
  }

  return <Outlet />;
};

export const useQueryRouteHandler = () => {
  const getQueryParams = useCallback((query: string | null = null) => {
    return (
      (query?.replace('?', '') || window.location.search.replace('?', ''))

        // get array of KeyValue pairs
        .split('&')

        // Decode values
        .map((pair) => {
          const [key, val] = pair.split('=');

          return [key, decodeURIComponent(val || '')];
        })

        // array to object
        .reduce((result: any, [key, val]) => {
          result[key] = val;
          return result;
        }, {})
    );
  }, []);

  /**
   * @description Parsing object searching to string as query
   * @param {Object} payload
   * @returns {String} Query
   */
  const parsingObjToQuery = useCallback(
    (payload: { [key: string]: string | number }): string => {
      const params = Object.entries(payload)
        .filter(
          ([key, value]) =>
            value !== null && value !== undefined && value !== ''
        )
        .map((e) => e.join('='))
        .join('&');
      return params;
    },
    []
  );

  // Return Fn
  return { getQueryParams, parsingObjToQuery };
};
