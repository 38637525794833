import React from "react";
import { useNavigate } from "react-router-dom";
import { useForm, Controller } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { Typography, Form, Input, Button, message } from "antd";
import { zodResolver } from "@hookform/resolvers/zod";
import { useMutation } from "@tanstack/react-query";
import backgroundSvg from "../../assets/svg/login-background.svg";
import { useMediaQuery } from "react-responsive";
import { logoUrl } from "../../utils/constant";
import {
  ForgotPasswordModel,
  ForgotPasswordSchema,
} from "./type/ForgotPasswordSchema";
import { forgotPassword } from "./api/forgotPasswordApi";

const { Text, Link } = Typography;

const ForgotPasswordPage = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const isMobile = useMediaQuery({ maxWidth: 767 });

  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm<ForgotPasswordModel>({
    resolver: zodResolver(ForgotPasswordSchema),
  });

  const { mutate: forgotPasswordMutate, isPending } = useMutation({
    mutationFn: forgotPassword,
    onSuccess: () => {
      message.success(t("forgotPasswordSuccess"));
      navigate("/login");
    },
    onError: (error) => {
      handleForgotPasswordError(error);
    },
  });

  const onSubmit = (data: ForgotPasswordModel) => {
    forgotPasswordMutate(data);
  };

  const handleForgotPasswordError = (error: any) => {
    if (error.response) {
      message.error(error.response.data.message || error.response.data.error);
    } else if (error.request) {
      message.error(t("networkError"));
    } else {
      message.error(t("forgotPasswordError"));
    }
    console.error("Forgot password error: ", error);
  };

  return (
    <main
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        background: `url(${backgroundSvg}) no-repeat center center `,
        backgroundSize: "cover",
        height: "100vh",
        padding: "0 20px",
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <img src={logoUrl} alt='logo' style={{ width: "80px" }} />
        <div style={{ height: "20px" }} />
        <div
          style={{
            padding: "40px",
            margin: "0 16px",
            borderRadius: "8px",
            boxShadow:
              "0px 4px 8px -4px rgba(255, 36, 66, 0.03), 0px 20px 30px -4px rgba(255, 36, 66, 0.08)",
            background: "white",
            width: isMobile ? "auto" : "560px",
            boxSizing: "border-box",
          }}
        >
          <div />
          <Text style={{ color: "#333333", fontSize: 20, fontWeight: 600 }}>
            {t("resetYourPassword")}
          </Text>
          <div style={{ height: "24px" }} />
          <div style={{ display: "flex", flexDirection: "column" }}>
            <div style={{ display: "flex", flexDirection: "column" }}>
              <Form onFinish={handleSubmit(onSubmit)} layout='vertical'>
                <Form.Item
                  label={t("email")}
                  name='email'
                  style={{ color: "#344054" }}
                  validateStatus={errors.email ? "error" : ""}
                  help={errors.email?.message}
                >
                  <Controller
                    name='email'
                    control={control}
                    defaultValue=''
                    render={({ field }) => (
                      <Input
                        {...field}
                        type='email'
                        autoComplete='email'
                        placeholder={t("email")}
                      />
                    )}
                  />
                </Form.Item>
                <div style={{ height: "16px" }} />
                <Form.Item>
                  <div style={{ display: "flex", flexDirection: "column" }}>
                    <Button
                      type='primary'
                      htmlType='submit'
                      block
                      loading={isPending}
                      style={{ minHeight: 44 }}
                    >
                      {t("resetPassword")}
                    </Button>
                  </div>
                </Form.Item>
              </Form>
            </div>
            <Link
              style={{
                alignSelf: "center",
                color: "#7E5435",
              }}
              onClick={() => navigate("/login")}
            >
              {t("backToSignIn")}
            </Link>
          </div>
        </div>
      </div>
    </main>
  );
};

export default ForgotPasswordPage;
