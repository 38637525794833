import React, { useContext, useEffect, useMemo } from "react";
import {
  Col,
  Empty,
  Flex,
  Layout,
  Row,
  Skeleton,
  Space,
  Table,
  TableProps,
  Typography,
} from "antd";
import HokkienDictionaryHeader from "../../components/layout/HokkienDictionaryHeader";
import HokkienDictionaryFooter from "../../components/layout/HokkienDictionaryFooter";
import { useTranslation } from "react-i18next";
import { TrophyOutlined } from "@ant-design/icons";
import "./Leaderboard.css";
import {
  fetchLeaderboard,
  fetchLeaderboardRankOfUser,
} from "./api/LeaderboardAPI";
import { useQuery } from "@tanstack/react-query";
import {
  IDatatableLeaderboard,
  LeaderboardResponse,
} from "./types/LeaderboardTypes";
import { AuthContext } from "../../context/AuthContext";
import Text from "../../components/base/Text";
import SkeletonBar from "./view/SkeletonBar";
import { useMediaQuery } from "react-responsive";

const { Content } = Layout;

const Leaderboard = () => {
  const { t } = useTranslation();

  // fetch data
  const { data: dataLeaderboard, isLoading: loadingLeaderboard } = useQuery({
    queryKey: ["leaderboard"],
    queryFn: fetchLeaderboard,
  });
  const {
    data: userRanking,
    isLoading: loadingUserRank,
    refetch,
  } = useQuery({
    queryKey: ["user-rank"],
    queryFn: fetchLeaderboardRankOfUser,
    enabled: false,
  });

  // Context
  const authContext = useContext(AuthContext);
  const isMobile = useMediaQuery({ query: "(max-width: 768px)" });

  // slice leaderboard data top 3
  const dataTopTree = useMemo(() => {
    const dataFiltered = dataLeaderboard?.results?.slice(0, 3) || [];

    if (dataFiltered.length > 0) {
      // Ensure we're not modifying the original array
      return [dataFiltered[1], dataFiltered[0], dataFiltered[2]];
    } else {
      return null;
    }
  }, [dataLeaderboard]);

  const rankingOfUser = useMemo((): number | null => {
    const findUser = dataLeaderboard?.results.findIndex(
      (item) => item?._id === userRanking?._id
    );

    if (Number(findUser) < 0) {
      return null;
    } else {
      return Number(findUser) + 1;
    }
  }, [dataLeaderboard?.results, userRanking]);

  const columns: TableProps<IDatatableLeaderboard>["columns"] = [
    {
      title: t("rank"),
      dataIndex: "rank",
      key: "rank",
      width: 20,
    },
    {
      title: t("name"),
      dataIndex: "name",
      key: "name",
      render: (data) => (
        <div style={{ display: "flex" }}>
          <Space>
            <div>
              <img
                src={data?.imageURL}
                alt='avatar'
                style={{ width: 32, height: 32, borderRadius: "50%" }}
              />
            </div>
            <Text label={`${data?.username}`} fontSize={22} />
          </Space>
        </div>
      ),
    },
    {
      title: t("wordContributed"),
      dataIndex: "totalSuggestionApprove",
      key: "totalSuggestionApprove",
      align: "end",
    },
  ];

  useEffect(() => {
    if (authContext?.user) {
      refetch();
    }
  }, [authContext?.user, refetch]);
  return (
    <Layout>
      <HokkienDictionaryHeader />
      <Content
        style={{
          padding: "0 30px",
          maxWidth: 1100,
          margin: isMobile ? undefined : "0 145px",
        }}
      >
        <div style={{ height: 40 }} />
        <Row justify={"space-between"}>
          <Col xs={24} md={24} lg={18} className='main-content'>
            {/* Chart */}
            <div
              className='bar-chart-container'
              style={{
                display: "flex",
                flex: 1,
                justifyContent: "space-around",
                alignItems: "flex-end",
                textAlign: "center",
                backgroundColor: "#f2eeeb",
                paddingTop: "20px",
                borderRadius: "10px",
                gap: isMobile ? "5px" : "32px",
                overflowX: "auto",
                padding: isMobile ? "20px 0 0 0" : "20px 40px 0 40px",
                whiteSpace: "nowrap",
              }}
            >
              {/* {dataTopTree && dataTopTree?.length > 0 ? (
                dataTopTree?.map((item, idx) => ( */}

              {/* Rank 2 */}
              {loadingLeaderboard ? (
                <SkeletonBar height={220} width={160} />
              ) : (
                <div
                  className='bar-chart-item'
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    maxWidth: isMobile ? "20vw" : "160px",
                  }}
                >
                  {dataTopTree?.[0]?.user.imageURL && (
                    <img
                      src={dataTopTree?.[0]?.user.imageURL}
                      alt='avatar'
                      style={{ width: 64, height: 64, borderRadius: "50%" }}
                    />
                  )}
                  <div style={{ height: 10 }} />
                  <Text
                    label={`${dataTopTree?.[0]?.user?.username || " "}`}
                    title={dataTopTree?.[0]?.user?.username || " "}
                    fontWeight={600}
                    fontSize={"1.3em"}
                    style={{
                      width: "100%",
                      textOverflow: "ellipsis",
                      overflow: "hidden",
                      textWrap: "nowrap",
                    }}
                  />
                  <div
                    className='chart-bar'
                    style={{
                      width: isMobile ? "20vw" : "160px",
                      backgroundColor: "#7e5435",
                      borderRadius: "20px 20px 0 0",
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      alignItems: "center",
                      padding: "10px",
                      transition: "box-shadow 0.3s ease-in-out",
                      height: "200px",
                      opacity: 0.7,
                    }}
                  >
                    <Text
                      label={t("secondPlace")}
                      fontWeight={600}
                      fontSize={28}
                      className='chart-bar-text'
                      style={{
                        color: "#fff",
                      }}
                    />
                    <Text
                      label={t("wordContributed", {
                        word: dataTopTree?.[0]?.totalSuggestionApproved ?? 0,
                      })}
                      className='chart-bar-text'
                      style={{
                        color: "#fff",
                        textWrap: "wrap",
                      }}
                    />
                  </div>
                </div>
              )}

              {/* Rank 1 */}
              {loadingLeaderboard ? (
                <SkeletonBar height={320} width={160} />
              ) : (
                <div
                  className='bar-chart-item'
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    maxWidth: isMobile ? "20vw" : "160px",
                  }}
                >
                  {dataTopTree?.[1]?.user.imageURL && (
                    <img
                      src={dataTopTree?.[1]?.user.imageURL}
                      alt='avatar'
                      style={{ width: 64, height: 64, borderRadius: "50%" }}
                    />
                  )}
                  <div style={{ height: 10 }} />
                  <Text
                    label={dataTopTree?.[1]?.user.username}
                    title={dataTopTree?.[1]?.user.username}
                    fontWeight={600}
                    fontSize={"1.3em"}
                    style={{
                      width: "100%",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      textWrap: "nowrap",
                    }}
                  />
                  <div
                    className='chart-bar'
                    style={{
                      width: isMobile ? "20vw" : "160px",
                      backgroundColor: "#7e5435",
                      borderRadius: "20px 20px 0 0",
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      alignItems: "center",
                      padding: "10px",
                      transition: "box-shadow 0.3s ease-in-out",
                      height: "300px",
                      opacity: 1,
                    }}
                  >
                    <Text
                      label={t("firstPlace")}
                      fontWeight={600}
                      fontSize={28}
                      className='chart-bar-text'
                      style={{
                        color: "#fff",
                      }}
                    />
                    <Text
                      label={t("wordContributed", {
                        word: dataTopTree?.[1]?.totalSuggestionApproved ?? 0,
                      })}
                      className='chart-bar-text'
                      style={{
                        color: "#fff",
                        textWrap: "wrap",
                      }}
                    />
                  </div>
                </div>
              )}

              {/* Rank 3 */}
              {loadingLeaderboard ? (
                <SkeletonBar height={170} width={160} />
              ) : (
                <div
                  className='bar-chart-item'
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    maxWidth: isMobile ? "20vw" : "160px",
                  }}
                >
                  {dataTopTree?.[2]?.user.imageURL && (
                    <img
                      src={dataTopTree?.[2]?.user.imageURL}
                      alt='avatar'
                      style={{ width: 64, height: 64, borderRadius: "50%" }}
                    />
                  )}
                  <div style={{ height: 10 }} />
                  <Text
                    label={dataTopTree?.[2]?.user.username}
                    title={dataTopTree?.[2]?.user.username}
                    fontWeight={600}
                    fontSize={"1.3em"}
                    style={{
                      width: "100%",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      textWrap: "nowrap",
                    }}
                  />
                  <div
                    className='chart-bar'
                    style={{
                      width: isMobile ? "20vw" : "160px",
                      backgroundColor: "#7e5435",
                      borderRadius: "20px 20px 0 0",
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      alignItems: "center",
                      padding: "10px",
                      transition: "box-shadow 0.3s ease-in-out",
                      height: "150px",
                      opacity: 0.5,
                    }}
                  >
                    <Text
                      label={t("thirdPlace")}
                      fontWeight={600}
                      fontSize={28}
                      className='chart-bar-text'
                      style={{
                        color: "#fff",
                      }}
                    />
                    <Text
                      label={t("wordContributed", {
                        word: dataTopTree?.[2]?.totalSuggestionApproved ?? 0,
                      })}
                      className='chart-bar-text'
                      style={{
                        color: "#fff",
                        textWrap: "wrap",
                      }}
                    />
                  </div>
                </div>
              )}
            </div>
          </Col>
          {/* User ranking section */}
          <Col xs={24} md={24} lg={5}>
            {userRanking ? (
              <div className='side-panel'>
                <Space
                  size='large'
                  direction='vertical'
                  style={{
                    borderRadius: 10,
                    backgroundColor: "#fff",
                    padding: "24px 42px",
                    width: "100%",
                    marginBottom: 16,
                  }}
                >
                  {loadingUserRank ? (
                    <Skeleton.Input active size='large' />
                  ) : (
                    rankingOfUser && (
                      <Space direction='vertical'>
                        <Text
                          label={t("todaysRanking")}
                          style={{ color: "#98A2B3" }}
                        />
                        <Flex gap={10} align='center'>
                          <TrophyOutlined style={{ color: "#7E5435" }} />
                          <div style={{ flex: 1 }}>
                            <Text label={String(rankingOfUser)} />
                          </div>
                        </Flex>
                      </Space>
                    )
                  )}
                  <Space direction='vertical'>
                    <Text
                      label={t("contributed")}
                      style={{ color: "#98A2B3" }}
                    />
                    <Flex gap={10}>
                      <TrophyOutlined style={{ color: "#7E5435" }} />

                      {loadingUserRank ? (
                        <Skeleton.Input active size='small' />
                      ) : (
                        <Text
                          label={String(
                            userRanking?.totalSuggestionApproved ?? 0
                          )}
                        />
                      )}
                    </Flex>
                  </Space>
                </Space>
              </div>
            ) : null}
          </Col>
          {/* End user ranking section */}
        </Row>
        <div style={{ height: 32 }} />

        <Row>
          <Col xs={24} md={24} lg={18}>
            {/* Table */}
            {dataLeaderboard?.results && dataLeaderboard.results.length > 3 ? (
              <Table
                loading={loadingLeaderboard}
                showHeader={false}
                columns={columns}
                dataSource={dataLeaderboard.results
                  .slice(3)
                  .map((_, index) => ({
                    key: index.toString(),
                    rank: index + 4,
                    name: _.user,
                    totalSuggestionApprove: t("wordContributed", {
                      word: _.totalSuggestionApproved,
                    }),
                  }))}
                style={{
                  width: "100%",
                  borderRadius: 10,
                  overflowX: "auto",
                  whiteSpace: "nowrap",
                }}
                pagination={false}
              />
            ) : null}
          </Col>
          <Col lg={6} />
        </Row>

        <div style={{ height: 32 }} />
      </Content>
      <HokkienDictionaryFooter />
    </Layout>
  );
};

export default Leaderboard;
