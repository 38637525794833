import { Col, message, Row, Space } from 'antd';
import React, { useCallback } from 'react';
import Text from '../../../components/base/Text';
import { useNavigate } from 'react-router-dom';

const WordGroup = ({ title, items }: { title: string; items?: any[] }) => {
  const navigate = useNavigate();

  const navigateToWordDetail = useCallback(
    (relativeWordId: string) => {
      if (!relativeWordId) {
        return message.error('invalid word id');
      }
      navigate(`/search/word/${relativeWordId}`);
    },
    [navigate]
  );
  return (
    <div style={{ margin: '16px 0' }}>
      <Row gutter={[16, 16]} align='middle' style={{ alignItems: 'start' }}>
        <Col
          xs={24}
          md={{ flex: '130px' }}
          style={{ display: 'flex', alignItems: 'start' }}
        >
          <Text
            fontSize={14}
            fontWeight={600}
            style={{ color: '#636669', display: 'block', alignItems: 'start' }}
            label={`${title} `}
          />
        </Col>
        <Col xs={0} md={1}>
          <Text
            fontSize={14}
            fontWeight={600}
            style={{ color: '#636669', display: 'block' }}
            label={`: `}
          />
        </Col>
        <Col xs={24} md={19}>
          <Space size='middle' wrap>
            {items?.map((item, index) => (
              <div
                title={item.relativeHanji}
                key={index}
                onClick={() => navigateToWordDetail(item.relativeWordId)}
                style={{
                  border: '1px solid #7E5435',
                  borderRadius: '100px',
                  maxWidth: '200px',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  textWrap: 'nowrap',
                  cursor: 'pointer',
                  padding: '4px 12px',
                }}
              >
                <Text
                  fontSize={14}
                  style={{
                    color: '#7E5435',
                  }}
                  label={item.relativeHanji}
                />
              </div>
            ))}
          </Space>
        </Col>
      </Row>
    </div>
  );
};

export default WordGroup;
