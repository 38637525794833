import React from "react";
import { Modal } from "antd";
import { CheckCircleOutlined } from "@ant-design/icons";

interface SuccessModalProps {
  open: boolean;
  onClose: () => void;
}

const SuccessModal: React.FC<SuccessModalProps> = ({ open, onClose }) => {
  return (
    <Modal open={open} onCancel={onClose} footer={null} centered>
      <div>
        <CheckCircleOutlined style={{ fontSize: "48px", color: "#52c41a" }} />
        <div
          style={{ marginTop: "16px", fontSize: "18px", fontWeight: "bold" }}
        >
          提交成功！
        </div>
        <div style={{ marginTop: "8px" }}>感謝您為本網站給予的貢獻。</div>
      </div>
    </Modal>
  );
};

export default SuccessModal;
