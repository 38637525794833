import React, { useState, useRef, useContext, useCallback } from "react";
import { Button, Input, Typography, Form, Space, message, Modal } from "antd";
import {
  PlusCircleOutlined,
  AudioOutlined,
  DeleteOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import {
  useForm,
  Controller,
  useFieldArray,
  FieldValues,
  UseFormRegister,
} from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { z } from "zod";
import { useTranslation } from "react-i18next";
import { useMutation } from "@tanstack/react-query";
import axiosInstance from "../../common/axiosInstance";
import { AuthContext } from "../../context/AuthContext";
import { useNavigate } from "react-router-dom";
import ModalConfirm from "../base/ModalConfirm";
import { ReactComponent as StopIcon } from "../../assets/svg/stop-icon.svg";

const { Text } = Typography;
const { confirm } = Modal;

const addWordSchema = z.object({
  hanji: z.string().min(1, { message: "This field is required" }),
  roman: z.string().min(1, { message: "This field is required" }),
  exampleChinese: z
    .array(
      z.object({
        value: z.string().min(1, { message: "This field is required" }),
      })
    )
    .min(1, { message: "At least one example is required" }),
  originWord: z.string(),
});

type AddWordFormInputs = z.infer<typeof addWordSchema>;

const ContributeWordBox: React.FC = () => {
  const { t } = useTranslation();
  const [isRecording, setIsRecording] = useState(false);
  const [audioBlob, setAudioBlob] = useState<Blob | null>(null);
  const [audioUrl, setAudioUrl] = useState<string | null>(null);
  const [audioSize, setAudioSize] = useState<number | null>();
  const [modalOptions, setModalOptions] = useState({
    isConfirmOpen: false,
  });
  const [stream, setStream] = useState<MediaStream | null>(null);
  const mediaRecorderRef = useRef<MediaRecorder | null>(null);
  const audioChunksRef = useRef<Blob[]>([]);
  const authContext = useContext(AuthContext);
  const navigate = useNavigate();

  const {
    control,
    handleSubmit,
    formState: { errors, isValid },
    reset,
    register,
  } = useForm<AddWordFormInputs>({
    resolver: zodResolver(addWordSchema),
    defaultValues: {
      exampleChinese: [{ value: "" }],
    },
  });

  const { fields, append, remove } = useFieldArray({
    control,
    name: "exampleChinese",
  });

  const createSuggestionMutation = useMutation({
    mutationFn: async (data: {
      formData: AddWordFormInputs;
      audioFile: File | null;
    }) => {
      const formData = new FormData();

      formData.append("userId", authContext?.user?.user?.id || "");
      formData.append("hanji", data.formData.hanji);
      formData.append("roman", data.formData.roman);
      formData.append("originWord", data.formData?.originWord);

      const exampleChinese = data.formData.exampleChinese
        .map((example) => example.value.trim())
        .filter(Boolean);
      formData.append("exampleChinese", JSON.stringify(exampleChinese));

      if (data.audioFile) {
        formData.append("sound", data.audioFile, "audio.webm");
      }

      console.log("Data being sent:", {
        userId: formData.get("userId"),
        hanji: formData.get("hanji"),
        roman: formData.get("roman"),
        exampleChinese: JSON.parse(formData.get("exampleChinese") as string),
        sound: formData.get("sound"),
      });

      const response = await axiosInstance.post("/suggestions", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      return response.data;
    },
    onSuccess: () => {
      message.success(t("suggestionSubmittedSuccessfully"));
      reset();
      setAudioBlob(null);
      setAudioUrl(null);
    },
    onError: (error: any) => {
      message.error(t("failedToSubmitSuggestion"));
      console.error(
        "Error submitting suggestion:",
        error.response?.data || error
      );
    },
  });

  const handleModal = useCallback(
    (type: keyof typeof modalOptions, value: boolean): void => {
      setModalOptions((prev) => ({ ...prev, [type]: value }));
    },
    []
  );

  const onSubmit = (data: AddWordFormInputs) => {
    if (!authContext?.user) {
      handleModal("isConfirmOpen", true);
      return;
    }
    const audioFile = audioBlob
      ? new File([audioBlob], "audio.webm", { type: "audio/webm" })
      : null;

    createSuggestionMutation.mutate({
      formData: data,
      audioFile,
    });
  };

  const startRecording = async () => {
    try {
      const newStream = await navigator.mediaDevices.getUserMedia({
        audio: true,
      });
      setStream(newStream);
      const mediaRecorder = new MediaRecorder(newStream);
      mediaRecorderRef.current = mediaRecorder;
      audioChunksRef.current = [];

      mediaRecorder.ondataavailable = (event) => {
        if (event.data.size > 0) {
          audioChunksRef.current.push(event.data);
        }
      };

      mediaRecorder.onstop = () => {
        const audioBlob = new Blob(audioChunksRef.current, {
          type: "audio/webm",
        });

        if (audioBlob?.size) {
          setAudioSize(audioBlob.size);
        }
        setAudioBlob(audioBlob);
        const url = URL.createObjectURL(audioBlob);
        setAudioUrl(url);
      };

      mediaRecorder.start();
      setIsRecording(true);
    } catch (error) {
      console.error("Error accessing microphone:", error);
      message.error(t("failedToAccessMicrophone"));
    }
  };

  const stopRecording = () => {
    if (mediaRecorderRef.current && isRecording) {
      mediaRecorderRef.current.stop();
      setIsRecording(false);

      // Stop all tracks in the stream
      if (stream) {
        stream.getTracks().forEach((track) => track.stop());
        setStream(null);
      }
    }
  };

  const cancelRecording = () => {
    setAudioBlob(null);
    setAudioUrl(null);

    // Ensure the stream is stopped when canceling
    if (stream) {
      stream.getTracks().forEach((track) => track.stop());
      setStream(null);
    }
  };

  return (
    <div
      style={{
        backgroundColor: "#F4F1EF",
        border: "1px solid #D0D5DD",
        borderRadius: "10px",
        padding: "24px",
        maxWidth: "400px",
        margin: "0 auto",
      }}
    >
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          marginBottom: "16px",
        }}
      >
        <div style={{ display: "flex", alignItems: "center" }}>
          <PlusCircleOutlined
            style={{ fontSize: "24px", color: "#7E5435", marginRight: "8px" }}
          />
          <Text style={{ color: "#7E5435" }}>
            {t("contributeAnotherSaying")}
          </Text>
        </div>
      </div>
      <Form onFinish={handleSubmit(onSubmit)} layout='vertical'>
        <Form.Item
          label={<span style={{ color: "#344054" }}>{t("chineseWord")}</span>}
          validateStatus={errors.hanji ? "error" : ""}
          help={errors.hanji?.message && t("thisFieldIsRequired")}
        >
          <Controller
            name='hanji'
            control={control}
            render={({ field }) => (
              <Input {...field} placeholder={t("enterChineseWord")} />
            )}
          />
        </Form.Item>

        <Form.Item
          label={<span style={{ color: "#344054" }}>{t("romanization")}</span>}
          validateStatus={errors.roman ? "error" : ""}
          help={errors.roman?.message && t("thisFieldIsRequired")}
        >
          <Controller
            name='roman'
            control={control}
            render={({ field }) => (
              <Input {...field} placeholder={t("enterRomanization")} />
            )}
          />
        </Form.Item>

        <Form.Item
          label={
            <span style={{ color: "#344054" }}>{t("exampleSentences")}</span>
          }
          validateStatus={errors.exampleChinese ? "error" : ""}
          help={
            errors.exampleChinese?.message && t("atLeastOneExampleRequired")
          }
        >
          {fields.map((field, index) => (
            <Space
              key={field.id}
              style={{ display: "flex", marginBottom: 8 }}
              align='baseline'
            >
              <Controller
                name={`exampleChinese.${index}.value`}
                control={control}
                render={({ field }) => (
                  <Input {...field} placeholder={t("enterExampleSentence")} />
                )}
              />
              <Button onClick={() => remove(index)} icon={<DeleteOutlined />} />
            </Space>
          ))}
          <Button
            type='dashed'
            onClick={() => append({ value: "" })}
            block
            icon={<PlusOutlined />}
          >
            {t("addExample")}
          </Button>
        </Form.Item>

        <Form.Item
          label={<span style={{ color: "#344054" }}>{t("variant")}</span>}
        >
          <Controller
            name='originWord'
            control={control}
            render={({ field }) => (
              <Input {...field} placeholder={t("variantExample")} />
            )}
          />
        </Form.Item>
        <Form.Item
          label={<span style={{ color: "#344054" }}>{t("pronunciation")}</span>}
        >
          <div
            style={{
              backgroundColor: "#E4E1DE",
              borderRadius: "8px",
              padding: "16px",
              textAlign: "center",
            }}
          >
            {!audioUrl ? (
              <>
                <Button
                  shape='circle'
                  icon={
                    isRecording ? (
                      <StopIcon style={{ color: "white" }} />
                    ) : (
                      <AudioOutlined />
                    )
                  }
                  onClick={isRecording ? stopRecording : startRecording}
                  style={{ backgroundColor: "#7E5435", color: "white" }}
                />
                <Text style={{ display: "block", marginTop: "8px" }}>
                  {isRecording
                    ? t("recording")
                    : t("clickButtonToStartRecording")}
                </Text>
              </>
            ) : (
              <>
                <audio
                  controls
                  src={audioUrl}
                  style={{ width: "100%", marginBottom: "8px" }}
                />
                <Button
                  icon={<DeleteOutlined />}
                  onClick={cancelRecording}
                  style={{ backgroundColor: "#7E5435", color: "white" }}
                >
                  {t("cancelRecording")}
                </Button>
              </>
            )}
          </div>
          {Number(audioSize) > 50e6 && (
            <Text style={{ color: "#ff4d4f" }}>{t("maxFileUpload")}</Text>
          )}
        </Form.Item>

        <Form.Item>
          <Space style={{ width: "100%", justifyContent: "space-between" }}>
            <Button
              style={{
                backgroundColor: "#FFFFFF",
                color: "#344054",
                border: "1px solid #D0D5DD",
              }}
            >
              {t("cancel")}
            </Button>
            <Button
              type='primary'
              htmlType='submit'
              loading={createSuggestionMutation.isPending}
              disabled={
                createSuggestionMutation.isPending ||
                isRecording ||
                !isValid ||
                Number(audioSize) > 50e6
              }
            >
              {t("submit")}
            </Button>
          </Space>
        </Form.Item>
      </Form>
      <ModalConfirm
        open={modalOptions.isConfirmOpen}
        title={t("modalConfirmAuthorizationAccess")}
        footer={[
          <Button key='back' onClick={() => navigate("/register")}>
            {t("register")}
          </Button>,
          <Button
            key='submit'
            type='primary'
            onClick={() => navigate("/login")}
          >
            {t("login")}
          </Button>,
        ]}
        onCancel={() => handleModal("isConfirmOpen", false)}
        onOk={() => navigate("/login")}
      >
        {t("modalConfirmAuthorizationAccessContent")}
      </ModalConfirm>
    </div>
  );
};

export default ContributeWordBox;
