import { Flex, Input, Typography } from 'antd';
import { InputProps } from 'antd/lib';
import React from 'react';
import { IInputText } from './types';

const InputText = (props: IInputText) => {
  return (
    <Flex vertical>
      {props.label && <Typography>{props.label}</Typography>}
      <Input {...props} />
    </Flex>
  );
};

export default InputText;
