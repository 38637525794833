import { Col, Row, Typography, Divider } from "antd";
import { logoUrl } from "../../utils/constant";

const { Text, Link } = Typography;

const HokkienDictionaryFooter = () => {
  return (
    <footer
      style={{
        backgroundColor: "white",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        padding: "0 16px",
      }}
    >
      <div style={{ height: 64 }} />
      <a
        href='/'
        style={{
          display: "flex",
          alignItems: "center",
          textDecoration: "none",
        }}
      >
        <img src={logoUrl} alt='logo' style={{ height: 48, width: 48 }} />
      </a>
      <div style={{ height: 32 }} />
      <Row
        style={{
          display: "flex",
          justifyContent: "center",
          textAlign: "left",
          flexWrap: "wrap",
        }}
      >
        <Col xs={24} md={11} style={{ marginBottom: 16 }}>
          <div>
            <Text style={{ color: "#7E5435", display: "block" }}>
              主辦單位 Organiser
            </Text>
            <Text style={{ color: "#667085", display: "block" }}>
              Persatuan Bahasa Hokkien Pulau Pinang
            </Text>
            <Text style={{ color: "#667085", display: "block" }}>
              庇能福建話協會
            </Text>
            <Text style={{ color: "#667085", display: "block" }}>
              (PPM-015-07-04122014)
            </Text>
          </div>
        </Col>
        <Col xs={0} md={1} />
        <Col xs={24} md={11}>
          <div>
            <Text style={{ color: "#7E5435", display: "block" }}>
              聯絡我們 Contact Us
            </Text>
            <Link
              href='mailto:lianlok@speakhokkien.org'
              style={{ color: "#667085", display: "block" }}
            >
              lianlok@speakhokkien.org
            </Link>
          </div>
        </Col>
      </Row>
      <div style={{ height: 94 }} />
      <Divider style={{ margin: "0 0 16px 0" }} />
      <Row
        style={{
          width: "100%",
          textAlign: "center",
          flexWrap: "wrap",
        }}
      >
        <Col xs={24} md={12} style={{ marginBottom: 16 }}>
          <Text style={{ color: "#667085", display: "block" }}>
            © 2024 Persatuan Bahasa Hokkien Pulau Pinang 庇能福建話協會
            版權所有
          </Text>
        </Col>
        <Col xs={24} md={6}>
          <Link
            href='https://www.speakhokkien.org/terms-of-use'
            target='_blank'
            rel='noopener noreferrer'
            style={{ color: "#667085", display: "block" }}
          >
            Terms of Use
          </Link>
        </Col>
        <Col xs={24} md={6}>
          <Link
            href='https://www.speakhokkien.org/privacy-policy'
            target='_blank'
            rel='noopener noreferrer'
            style={{ color: "#667085", display: "block" }}
          >
            Privacy Policy
          </Link>
        </Col>
      </Row>
    </footer>
  );
};

export default HokkienDictionaryFooter;
