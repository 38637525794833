import axiosInstance from '../../../common/axiosInstance';
import { IGeneralResponse } from '../../../types/GenerateTypes';
import { LeaderboardResponse } from '../types/LeaderboardTypes';

export const fetchLeaderboard = async (): Promise<
  IGeneralResponse<LeaderboardResponse[]>
> => {
  try {
    const response = await axiosInstance.get<
      IGeneralResponse<LeaderboardResponse[]>
    >('/leaderboards/public?page=1&limit=10');
    return response.data;
  } catch (error) {
    console.error('Error fetching leaderboard:', error);
    throw error;
  }
};

export const fetchLeaderboardRankOfUser =
  async (): Promise<LeaderboardResponse> => {
    try {
      const response =
        await axiosInstance.get<LeaderboardResponse>('/leaderboards');
      return response.data;
    } catch (error) {
      console.error('Error fetching user ranking: ', error);
      throw error;
    }
  };
