import axiosInstance from '../../../common/axiosInstance';
import { ILikesPayload } from '../../../types/likes';

export const postLike = async (payload: ILikesPayload): Promise<any> => {
  try {
    const response = await axiosInstance.post(`/likes`, payload);
    return response.data;
  } catch (error) {
    console.log('Error post like: ', error);
  }
};
