import { z } from 'zod';

export const SignupSchema = z.object({
  username: z
    .string()
    .min(5, 'Username need to be more than 5 chars')
    .max(20, 'Username need to be less than 20 chars'),
  email: z
    .string()
    .email('Invalid email format')
    .min(5, 'Email need to be more than 5 chars')
    .max(255, 'Email need to be less than 255 chars'),

  password: z.string().min(5, 'Password need to be more than 5 chars'),
});

export type SignupModel = z.infer<typeof SignupSchema>;
