import React, { useCallback, useContext, useState } from 'react';
import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query';
import {
  Typography,
  Spin,
  message,
  Form,
  Input,
  Button,
  List,
  Avatar,
  Empty,
  Space,
  Row,
  Col,
  Tooltip,
  Skeleton,
} from 'antd';
import { UserOutlined } from '@ant-design/icons';
import { fetchComments, postComment } from '../api/CommentAPI';
import { CommentType } from '../type/CommentType';
import { AuthContext } from '../../../context/AuthContext';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';
import 'dayjs/locale/zh';
import relativeTime from 'dayjs/plugin/relativeTime';
import ModalConfirm from '../../../components/base/ModalConfirm';
import { useNavigate } from 'react-router-dom';

dayjs.extend(relativeTime);

const { Title, Text } = Typography;
const { TextArea } = Input;

interface CommentSectionProps {
  wordId: string;
}

const CommentSkeleton: React.FC = () => (
  <div style={{ maxWidth: '100%', padding: '0 16px' }}>
    <Skeleton.Input style={{ width: 200, marginBottom: 16 }} active />
    {[1, 2, 3].map((_, index) => (
      <div key={index} style={{ marginBottom: 16 }}>
        <Skeleton avatar active paragraph={{ rows: 2 }} />
      </div>
    ))}
    <Skeleton.Input
      style={{ width: '100%', height: 100, marginBottom: 16 }}
      active
    />
    <Skeleton.Button active />
  </div>
);

const CommentSection: React.FC<CommentSectionProps> = ({ wordId }) => {
  const queryClient = useQueryClient();
  const [form] = Form.useForm();
  const authContext = useContext(AuthContext);
  const { t } = useTranslation();
  const navigate = useNavigate();
  const locale = localStorage.getItem('i18nextLng');

  const [modalOptions, setModalOptions] = useState({
    isConfirmOpen: false,
  });

  if (!authContext) {
    throw new Error(t('errorsAuthContextMissing'));
  }

  const { user, isAuthenticated } = authContext;

  const {
    data: comments,
    isLoading,
    isError,
  } = useQuery<CommentType[], Error>({
    queryKey: ['comments', wordId],
    queryFn: () => fetchComments(wordId),
  });

  const mutation = useMutation<CommentType, Error, string>({
    mutationFn: (comment) => {
      if (!user) {
        throw new Error(t('errorsUserNotLoggedIn'));
      }
      return postComment(user?.user?.id, wordId, comment);
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ['comments', wordId],
      });
      form.resetFields();
      message.success(t('messagesCommentPostSuccess'));
    },
    onError: (error) => {
      message.error(t('errorsCommentPostFail', { error: error.message }));
    },
  });

  const handleModal = useCallback(
    (type: keyof typeof modalOptions, value: boolean): void => {
      setModalOptions((prev) => ({ ...prev, [type]: value }));
    },
    []
  );

  const handleSubmit = (values: { comment: string }) => {
    if (!isAuthenticated()) {
      handleModal('isConfirmOpen', true);
      return;
    }
    if (values.comment.trim()) {
      mutation.mutate(values.comment);
    }
  };

  const getAvatarContent = () => {
    if (isAuthenticated() && user?.user?.imageURL) {
      return (
        <Avatar
          size='default'
          src={user.user.imageURL}
          alt={user.user.username || t('commentsAnonymous')}
        />
      );
    } else {
      return (
        <Avatar
          size='default'
          icon={<UserOutlined />}
          style={{
            backgroundColor: '#F0F0F0',
            color: '#000000',
          }}
        />
      );
    }
  };

  if (isLoading) return <CommentSkeleton />;
  if (isError) return <Text>{t('errorsLoadingComments')}</Text>;

  return (
    <div style={{ maxWidth: '100%' }}>
      <Title
        level={3}
        style={{ marginBottom: 16, color: '#7E5435', fontSize: 18 }}
      >
        {t('commentsTitle')}
      </Title>
      {comments && comments.length > 0 ? (
        <List
          dataSource={comments}
          renderItem={(comment) => (
            <List.Item key={comment.id}>
              <div style={{ width: '100%' }}>
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'flex-start',
                    marginBottom: 8,
                  }}
                >
                  <Avatar
                    src={comment.userId?.imageURL}
                    alt={comment.userId?.username || t('commentsAnonymous')}
                    style={{ flexShrink: 0 }}
                  />
                  <div style={{ marginLeft: 16, flexGrow: 1 }}>
                    <div
                      style={{
                        display: 'flex',
                        flexWrap: 'wrap',
                        alignItems: 'center',
                      }}
                    >
                      <Text
                        style={{
                          color: '#667085',
                          fontSize: 14,
                          marginRight: 8,
                        }}
                      >
                        {comment.userId?.username || t('commentsAnonymous')}
                      </Text>
                      <Tooltip
                        title={dayjs(comment.createdAt).format(
                          'YYYY-MM-DD HH:mm:ss'
                        )}
                      >
                        <Text style={{ fontSize: 12, color: '#98A2B3' }}>
                          {dayjs(comment.createdAt)
                            .locale(
                              locale === 'zh' || locale === 'nan' ? 'zh' : 'en'
                            )
                            .fromNow()}
                        </Text>
                      </Tooltip>
                    </div>
                    <Text
                      style={{
                        display: 'block',
                        marginTop: 4,
                        textAlign: 'left',
                      }}
                    >
                      {comment.comment}
                    </Text>
                  </div>
                </div>
              </div>
            </List.Item>
          )}
        />
      ) : (
        <Empty
          image={Empty.PRESENTED_IMAGE_SIMPLE}
          description={t('commentsNoComments')}
        />
      )}
      <Form form={form} onFinish={handleSubmit} style={{ marginTop: 24 }}>
        <Row gutter={[8, 16]} align='top'>
          <Col xs={24} sm={1}>
            {getAvatarContent()}
          </Col>
          <Col xs={24} sm={23}>
            <Form.Item name='comment' style={{ marginBottom: 16 }}>
              <TextArea rows={4} placeholder={t('commentsInputPlaceholder')} />
            </Form.Item>
            <Form.Item>
              <Button
                type='primary'
                htmlType='submit'
                style={{ backgroundColor: '#7E5435' }}
              >
                {t('commentsSubmitButton')}
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </Form>

      <ModalConfirm
        open={modalOptions.isConfirmOpen}
        title={t('modalConfirmAuthorizationAccess')}
        footer={[
          <Button
            key='back'
            onClick={(e) => {
              e.stopPropagation();
              navigate('/register');
            }}
          >
            {t('register')}
          </Button>,
          <Button
            key='submit'
            type='primary'
            onClick={(e) => {
              e.stopPropagation();
              navigate('/login');
            }}
          >
            {t('login')}
          </Button>,
        ]}
        onCancel={(e) => {
          e.stopPropagation();
          handleModal('isConfirmOpen', false);
        }}
        onOk={(e) => {
          e.stopPropagation();
          navigate('/login');
        }}
      >
        {t('modalConfirmAuthorizationAccessContent')}
      </ModalConfirm>
    </div>
  );
};

export default CommentSection;
