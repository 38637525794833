import React, { useCallback, useState } from 'react';
import { Button, Flex, Typography, Card, Space, message } from 'antd';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useMutation } from '@tanstack/react-query';
import { verifyEmail } from '../../common/api/auth';
import { useTranslation } from 'react-i18next';
import { MailOutlined, CheckCircleOutlined } from '@ant-design/icons';
import ModalConfirm from '../../components/base/ModalConfirm';

const { Title, Paragraph } = Typography;

const VerifyEmail = () => {
  const [modalOptions, setModalOptions] = useState({
    isConfirmOpen: false,
  });
  const [searchParams] = useSearchParams();
  const { t } = useTranslation();
  const navigate = useNavigate();

  const token = searchParams.get('token');

  const { mutate, isPending } = useMutation({
    mutationKey: ['verified'],
    mutationFn: verifyEmail,
  });

  /**
   * @description Handle Verification Email
   *
   * @return {void} void
   */
  const handleVerification = useCallback(async () => {
    await mutate(token ?? '', {
      onSuccess: (data) => {
        message.success(data.message || t('emailVerified'));
        navigate('/login');
        return;
      },
      onError: (error: any) => {
        if (error.response) {
          message.error(
            error.response.data.message || error.response.data.error
          );
        } else if (error.request) {
          message.error(t('networkError'));
        }
        return;
      },
    });
  }, [mutate, token, navigate, t]);

  return (
    <Flex
      vertical
      justify='center'
      align='center'
      style={{ minHeight: '100vh', padding: '20px' }}
    >
      <Card
        style={{
          width: '100%',
          maxWidth: 400,
          boxShadow: '0 4px 8px rgba(0,0,0,0.1)',
        }}
      >
        <Space direction='vertical' size='large' style={{ width: '100%' }}>
          <Flex vertical align='center'>
            <MailOutlined style={{ fontSize: 48, color: '#595959' }} />

            <Title level={3} style={{ marginTop: 16, color: '#262626' }}>
              {t('verifyYourEmail')}
            </Title>
          </Flex>
          <Paragraph style={{ textAlign: 'center', color: '#595959' }}>
            {t('textVerificationEmailBody')}
          </Paragraph>
          <Button
            onClick={handleVerification}
            loading={isPending}
            type='primary'
            size='large'
            block
          >
            {t('verifyEmailButton')}
          </Button>
        </Space>
      </Card>
    </Flex>
  );
};

export default VerifyEmail;
