import React, {
  useRef,
  useState,
  useEffect,
  useMemo,
  useCallback,
  useContext,
} from 'react';
import {
  Button,
  Typography,
  Tooltip,
  Skeleton,
  Flex,
  Space,
  Badge,
  Modal,
  Spin,
} from 'antd';
import {
  CommentOutlined,
  HeartOutlined,
  SoundOutlined,
  LoadingOutlined,
  PauseOutlined,
  LikeOutlined,
  DislikeOutlined,
  LikeFilled,
  DislikeFilled,
  SoundFilled,
} from '@ant-design/icons';
import { ReactComponent as StopIcon } from '../../assets/svg/stop-icon.svg';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { IWordListsResponse } from '../../types/word';
import { AuthContext } from '../../context/AuthContext';
import { useMutation } from '@tanstack/react-query';
import { postLike } from '../../pages/SearchResults/api/LikeAPI';
import { LikeContext } from '../../context/LikeContext';
import ModalConfirm from '../base/ModalConfirm';

const { Text, Link } = Typography;
const { confirm } = Modal;

type IData = IWordListsResponse & { isLike?: boolean; randomWordId?: string };
interface HokkienWordBoxProps {
  data: IData;
  loading?: boolean;
}

const HokkienWordBox: React.FC<HokkienWordBoxProps> = ({
  data,
  loading = false,
}) => {
  const audioRef = useRef<HTMLAudioElement>(null);
  const [isPlaying, setIsPlaying] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [validAudioURL, setValidAudioURL] = useState(true);
  const [progress, setProgress] = useState(0);
  const [isLike, setIslike] = useState<boolean | undefined>(data?.isLike);
  const [totalLike, setTotalLike] = useState<number>(data?.totalLike ?? 0);
  const [totalDislike, setTotalDislike] = useState<number>(
    data?.totalDislike ?? 0
  );
  const [modalOptions, setModalOptions] = useState({
    isConfirmOpen: false,
  });

  const { t } = useTranslation();
  const navigate = useNavigate();
  const authContext = useContext(AuthContext);
  const likeContext = useContext(LikeContext);

  const isDailyWord = useMemo((): boolean => {
    if (data?.randomWordId) {
      return true;
    } else {
      return false;
    }
  }, [data]);

  if (!likeContext) {
    throw new Error('LikeButton must be used within a LikeProvider');
  }

  const { likes, setLike, setDislike } = likeContext;

  // Temporarily get user info from authContext or localStorage
  const user =
    authContext?.user || JSON.parse(localStorage.getItem('user') || 'null');

  // React Query
  const { mutate: createLike, isPending } = useMutation({
    mutationKey: ['likes'],
    mutationFn: postLike,
  });

  const exampleHanjiMap = useMemo((): string => {
    const item = data?.meaning?.map((item) =>
      item.example?.map((text) => text.exampleHanji)
    );
    return item?.join('') ?? '';
  }, [data]);

  useEffect(() => {
    const audio = audioRef.current;
    if (audio) {
      const updateProgress = () => {
        setProgress((audio.currentTime / audio.duration) * 100);
      };
      audio.addEventListener('timeupdate', updateProgress);
      return () => {
        audio.removeEventListener('timeupdate', updateProgress);
      };
    }
  }, []);

  /**
   * @description Handle modal
   *
   * @param {string} type
   * @param {boolean} value
   *
   * @return {void} void
   */
  const handleModal = useCallback(
    (type: keyof typeof modalOptions, value: boolean): void => {
      setModalOptions((prev) => ({ ...prev, [type]: value }));
    },
    []
  );

  const handlePlayPauseAudio = useCallback(
    (e: React.MouseEvent<HTMLSpanElement>) => {
      e.stopPropagation();
      if (!data?.recordURL || !validAudioURL) {
        return;
      }

      if (audioRef?.current) {
        if (isPlaying) {
          audioRef.current.pause();
          audioRef.current.currentTime = 0;
          setIsPlaying(false);
        } else {
          setIsLoading(true);
          audioRef.current
            .play()
            .then(() => {
              setIsLoading(false);
              setIsPlaying(true);
            })
            .catch((error) => {
              console.error('Error playing audio:', error);
              setIsLoading(false);
            });
        }
      }
    },
    [data?.recordURL, validAudioURL, isPlaying]
  );

  const handleAudioEnded = () => {
    setIsPlaying(false);
    setProgress(0);
  };

  const handleLike = useCallback(
    async (wordId: string) => {
      if (!user) {
        handleModal('isConfirmOpen', true);
        return;
      }
      const payload = {
        userId: user?.user?.id,
        wordId,
        // randomWordId: data?.randomWordId,
        isLike: true,
      };
      await createLike(payload);
      if (likes[data?.wordId]?.value === false && totalDislike > 0) {
        setTotalDislike(totalDislike - 1);
      }
      setTotalLike((prev) => prev + 1);
      setLike(wordId);
      setIslike(true);

      return;
    },
    [createLike, totalDislike, handleModal, data, likes, user, setLike]
  );
  const handleDislike = useCallback(
    async (wordId: string) => {
      if (!user) {
        handleModal('isConfirmOpen', true);
        return;
      }
      const payload = {
        userId: user?.user?.id,
        wordId,
        // randomWordId: data?.randomWordId,
        isLike: false,
      };
      await createLike(payload);
      if (likes[data?.wordId]?.value === true && Number(totalLike ?? 0) > 0) {
        setTotalLike(Number(totalLike ?? 0) - 1);
      }
      setTotalDislike((prev) => prev + 1);
      setDislike(wordId);
      setIslike(false);
      return;
    },
    [createLike, totalLike, handleModal, likes, data, user, setDislike]
  );

  if (loading) {
    return (
      <div
        style={{
          border: '1px solid #D0D5DD',
          borderRadius: 10,
          padding: 24,
          backgroundColor: '#fff',
        }}
      >
        <Skeleton active paragraph={{ rows: 4 }} />
      </div>
    );
  }

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        border: '1px solid #D0D5DD',
        borderRadius: 10,
        padding: 24,
        backgroundColor: '#fff',
        cursor: 'pointer',
        transition: 'box-shadow 0.3s ease-in-out',
      }}
      onMouseEnter={(e) => {
        e.currentTarget.style.boxShadow = '0 4px 12px rgba(0, 0, 0, 0.1)';
      }}
      onMouseLeave={(e) => {
        e.currentTarget.style.boxShadow = 'none';
      }}
    >
      <Flex justify='space-between' align='center'>
        <Text
          style={{
            fontWeight: '600',
            fontSize: 24,
            textOverflow: 'ellipsis',
            textWrap: 'nowrap',
            overflow: 'hidden',
          }}
        >
          {data.hanji}
        </Text>

        {!isDailyWord ? (
          <Space size='middle'>
            <Badge count={likes[data?.wordId]?.totalLike} size='small'>
              <Button
                onClick={(e) => {
                  e.stopPropagation();
                  if (
                    likes[data?.wordId]?.value === false ||
                    likes[data?.wordId]?.value === undefined
                  ) {
                    handleLike(data?.wordId);
                  }
                }}
                size='small'
                icon={
                  isPending && isLike ? (
                    <Spin indicator={<LoadingOutlined spin />} size='small' />
                  ) : likes[data?.wordId]?.value ? (
                    <LikeFilled />
                  ) : (
                    <LikeOutlined />
                  )
                }
              />
            </Badge>
            <Badge
              count={likes[data?.wordId]?.totalDislike}
              status='default'
              size='small'
            >
              <Button
                onClick={(e) => {
                  e.stopPropagation();
                  if (
                    likes[data?.wordId]?.value === true ||
                    likes[data?.wordId]?.value === undefined
                  ) {
                    handleDislike(data?.wordId);
                  }
                }}
                size='small'
                icon={
                  isPending && !isLike ? (
                    <Spin indicator={<LoadingOutlined spin />} size='small' />
                  ) : likes[data?.wordId]?.value === false ? (
                    <DislikeFilled />
                  ) : (
                    <DislikeOutlined />
                  )
                }
              />
            </Badge>
          </Space>
        ) : (
          ''
        )}
      </Flex>
      <div
        style={{
          flexDirection: 'row',
          display: 'flex',
          alignItems: 'center',
        }}
      >
        <Text
          style={{
            textOverflow: 'ellipsis',
            textWrap: 'nowrap',
            overflow: 'hidden',
          }}
        >
          {data.roman}
        </Text>
        <div style={{ width: 16 }} />
        {isLoading ? (
          <LoadingOutlined style={{ color: '#7E5435', fontSize: 16 }} />
        ) : (
          <>
            <Tooltip
              title={
                !data?.recordURL
                  ? t('audioNotFound')
                  : !validAudioURL
                    ? t('invalidAudioSource')
                    : isPlaying
                      ? t('stop')
                      : t('play')
              }
            >
              <span
                onClick={handlePlayPauseAudio}
                style={{
                  cursor:
                    !data?.recordURL || !validAudioURL
                      ? 'not-allowed'
                      : 'pointer',
                }}
              >
                {isPlaying ? (
                  <StopIcon
                    style={{
                      color: '#7E5435',
                      fontSize: 16,
                      cursor: 'pointer',
                    }}
                  />
                ) : !validAudioURL ? (
                  <SoundFilled style={{ color: '#d9d9d9' }} />
                ) : (
                  <SoundOutlined style={{ color: '#7E5435', fontSize: 16 }} />
                )}
              </span>
            </Tooltip>
          </>
        )}
      </div>
      <audio
        ref={audioRef}
        onEnded={handleAudioEnded}
        onError={(e) => setValidAudioURL(false)}
        controls
        style={{ display: 'none' }}
      >
        <source src={data?.recordURL} type={data?.recordType} />
        Your browser does not support the audio element.
      </audio>

      <div style={{ height: 16 }} />
      <div
        style={{
          backgroundColor: '#F2EEEB',
          borderRadius: 10,
          padding: 8,
          textOverflow: 'ellipsis',
          textWrap: 'nowrap',
          overflow: 'hidden',
        }}
      >
        <Text>
          {t('exampleSentence')}: {exampleHanjiMap ? exampleHanjiMap : '-'}
        </Text>
      </div>
      <div style={{ height: 16 }} />
      <Text
        style={{
          textOverflow: 'ellipsis',
          textWrap: 'nowrap',
          overflow: 'hidden',
        }}
      >
        {t('source')}: {data?.dictionary?.name ?? '-'}
      </Text>
      <div style={{ height: 16 }} />
      <ModalConfirm
        open={modalOptions.isConfirmOpen}
        title={t('modalConfirmAuthorizationAccess')}
        footer={[
          <Button
            key='back'
            onClick={(e) => {
              e.stopPropagation();
              navigate('/register');
            }}
          >
            {t('register')}
          </Button>,
          <Button
            key='submit'
            type='primary'
            onClick={(e) => {
              e.stopPropagation();
              navigate('/login');
            }}
          >
            {t('login')}
          </Button>,
        ]}
        onCancel={(e) => {
          e.stopPropagation();
          handleModal('isConfirmOpen', false);
        }}
        onOk={(e) => {
          e.stopPropagation();
          navigate('/login');
        }}
      >
        {t('modalConfirmAuthorizationAccessContent')}
      </ModalConfirm>
    </div>
  );
};

export default HokkienWordBox;
