import React, { createContext, useState, useEffect, ReactNode } from 'react';
import Cookies from 'js-cookie';
import { User } from '../common/type/User';

interface AuthContextType {
  user: User | null;
  login: (userData: User) => void;
  logout: () => void;
  signup: (data: User) => void;
  updateUser: (data: User) => void;
  isAuthenticated: () => boolean;
  getAccessToken: () => string | null;
  getRefreshToken: () => string | null;
  setAccessToken: (token: string) => void;
  setRefreshToken: (token: string) => void;
}

export const AuthContext = createContext<AuthContextType | undefined>(
  undefined
);

export const AuthProvider = ({ children }: { children: ReactNode }) => {
  const [user, setUser] = useState<User | null>(() => {
    const savedUser = Cookies.get('user');
    return savedUser ? JSON.parse(savedUser) : null;
  });

  useEffect(() => {
    const accessToken = Cookies.get('accessToken');
    const refreshToken = Cookies.get('refreshToken');
    const savedUser = Cookies.get('user');
    if (accessToken && refreshToken && savedUser) {
      setUser(JSON.parse(savedUser));
    }
  }, []);

  const login = (userData: User) => {
    setUser(userData);
    Cookies.set('user', JSON.stringify(userData), { expires: 30 });
    Cookies.set('accessToken', userData.tokens.access.token, { expires: 30 });
    Cookies.set('refreshToken', userData.tokens.refresh.token, { expires: 30 });
  };

  const updateUser = (data: User) => {
    setUser(data);
    Cookies.set('use', JSON.stringify(data), { expires: 30 });
  };

  const logout = () => {
    setUser(null);
    Cookies.remove('user');
    Cookies.remove('accessToken');
    Cookies.remove('refreshToken');
  };

  const signup = async (data: User) => {
    setUser(data);
    Cookies.set('user', JSON.stringify(data), { expires: 30 });
    Cookies.set('accessToken', data.tokens.access.token, { expires: 30 });
    Cookies.set('refreshToken', data.tokens.refresh.token, { expires: 30 });
  };

  const isAuthenticated = () => {
    return user !== null && Cookies.get('accessToken') !== undefined;
  };

  const getAccessToken = (): string | null => {
    return Cookies.get('accessToken') || null;
  };

  const getRefreshToken = (): string | null => {
    return Cookies.get('refreshToken') || null;
  };

  const setAccessToken = (token: string) => {
    Cookies.set('accessToken', token, { expires: 30 });
  };

  const setRefreshToken = (token: string) => {
    Cookies.set('refreshToken', token, { expires: 30 });
  };

  return (
    <AuthContext.Provider
      value={{
        user,
        login,
        logout,
        signup,
        isAuthenticated,
        getAccessToken,
        getRefreshToken,
        setAccessToken,
        setRefreshToken,
        updateUser,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export default AuthProvider;
