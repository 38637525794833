import React, { useMemo, useRef, useState } from 'react';
import {
  Col,
  Layout,
  Row,
  Typography,
  Flex,
  Space,
  Tooltip,
  Skeleton,
} from 'antd';
import { useTranslation } from 'react-i18next';
import { LoadingOutlined, SoundFilled, SoundOutlined } from '@ant-design/icons';
import { useParams } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import HokkienDictionaryFooter from '../../components/layout/HokkienDictionaryFooter';
import HokkienDictionaryHeader from '../../components/layout/HokkienDictionaryHeader';
import { WordDetail } from './type/WordDetailType';
import { fetchWordDetail } from './api/WordDetailAPI';
import HokkienExample from '../../components/ui/HokkienSentence';
import CommentSection from './view/CommentSection';
import { IWordListsResponse } from '../../types/word';
import Text from '../../components/base/Text';
import WordGroup from './view/WordGroup';
import { ReactComponent as StopIcon } from '../../assets/svg/stop-icon.svg';

const { Content } = Layout;
const { Link } = Typography;

const useWordDetail = (id: string) => {
  return useQuery({
    queryKey: ['wordDetail', id],
    queryFn: () => fetchWordDetail(id),
  });
};

const WordDetailSkeleton: React.FC = () => {
  return (
    <Layout>
      <HokkienDictionaryHeader />
      <Content>
        <div style={{ maxWidth: 1100, margin: '0 auto', padding: 24 }}>
          <Row gutter={[16, 16]}>
            <Col xs={24} md={16}>
              <Space
                direction='vertical'
                size='large'
                style={{ width: '100%' }}
              >
                <Skeleton.Input style={{ width: 200 }} active size='large' />
                <Skeleton.Input style={{ width: 150 }} active />
                <Space>
                  <Skeleton.Button active size='small' shape='circle' />
                  <Skeleton.Button active size='small' shape='circle' />
                </Space>
                <Skeleton paragraph={{ rows: 2 }} active />
              </Space>
            </Col>
            <Col xs={24} md={8}>
              <Skeleton paragraph={{ rows: 2 }} active />
            </Col>
          </Row>

          <div style={{ height: 24 }} />

          {/* Skeleton for HokkienExample components */}
          {[1, 2].map((_, index) => (
            <div key={index} style={{ marginBottom: 24 }}>
              <Skeleton.Input style={{ width: 150, marginBottom: 16 }} active />
              <Skeleton paragraph={{ rows: 3 }} active />
            </div>
          ))}

          {/* Skeleton for CommentSection */}
          <div style={{ marginTop: 24 }}>
            <Skeleton.Input style={{ width: 200, marginBottom: 16 }} active />
            <Skeleton paragraph={{ rows: 4 }} active />
          </div>
        </div>
      </Content>
      <HokkienDictionaryFooter />
    </Layout>
  );
};

const WordDetailComponent: React.FC = () => {
  const { t } = useTranslation();
  const { wordId } = useParams<{ wordId: string }>();
  const {
    data: wordDetail,
    isLoading: loadingWord,
    isError,
  } = useWordDetail(wordId ?? '');

  const [isPlaying, setIsPlaying] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [validAudioURL, setValidAudioURL] = useState(true);

  const audioRef = useRef<HTMLAudioElement>(null);

  const displayMandarinChinese = (
    otherLanguages?: WordDetail['otherLanguages']
  ): string => {
    return otherLanguages?.map((lang) => lang.chinese).join(' ') ?? '';
  };

  const wordMeaningMandarinMap = useMemo(() => {
    const meaningMandarin = wordDetail?.meanings
      ?.filter((item) => item.meaningChinese)
      .map((item) => item.meaningChinese);

    if (Number(meaningMandarin?.length) <= 0) {
      return null;
    } else {
      return meaningMandarin?.join(', ');
    }
  }, [wordDetail]);

  const wordMeaningPartOfSpeechMap = useMemo(() => {
    const meaningMandarin = wordDetail?.meanings?.map(
      (item) => item.partOfSpeech
    );
    return meaningMandarin?.join(', ');
  }, [wordDetail]);

  const wordAlternateChineseMap = useMemo(() => {
    const meaningMandarin = wordDetail?.alternativeCharacters
      ?.filter((item) => item.alternativeCharacterChinese)
      .map((item) => item.alternativeCharacterChinese);
    if (Number(meaningMandarin?.length) <= 0) {
      return null;
    } else {
      return meaningMandarin?.join(', ');
    }
  }, [wordDetail]);

  const wordAlternateEnglishMap = useMemo(() => {
    const meaningMandarin = wordDetail?.alternativeCharacters
      ?.filter((item) => item.alternativeCharacterEnglish)
      .map((item) => item.alternativeCharacterEnglish);
    if (Number(meaningMandarin?.length) <= 0) {
      return null;
    } else {
      return meaningMandarin?.join(', ');
    }
  }, [wordDetail]);

  const wordAlternateVariantMap = useMemo(() => {
    const meaningMandarin = wordDetail?.alternativeCharacters?.map(
      (item) => item.alternativeCharacterHanji
    );
    return meaningMandarin?.join(', ');
  }, [wordDetail]);

  const wordPronunciationMap = useMemo(() => {
    const meaningMandarin = wordDetail?.alternativePronounces?.map(
      (item) => item.alternativePronounceRoman
    );
    return meaningMandarin?.join(', ');
  }, [wordDetail]);

  const wordPronunciationMixWordMap = useMemo(() => {
    const meaningMandarin = wordDetail?.mixWords?.map(
      (item) => item.mixWordRoman
    );
    return meaningMandarin?.join(', ');
  }, [wordDetail]);

  const wordPronunciationCorruptedMap = useMemo(() => {
    const meaningMandarin = wordDetail?.corruptedPronounces?.map(
      (item) => item.corruptedPronounceRoman
    );
    return meaningMandarin?.join(', ');
  }, [wordDetail]);

  // Audio Handler

  const handlePlayPauseAudio = async (e: React.MouseEvent<HTMLSpanElement>) => {
    e.stopPropagation();
    if (!wordDetail?.recordURL || !validAudioURL) {
      return;
    }

    if (audioRef?.current) {
      if (isPlaying) {
        audioRef.current.pause();
        setIsPlaying(false);
      } else {
        setIsLoading(true);
        audioRef.current
          .play()
          .then(() => {
            setIsLoading(false);
            setIsPlaying(true);
          })
          .catch((error) => {
            console.error('Error playing audio:', error);
            setIsLoading(false);
          });
      }
    }
  };

  const handleStopAudio = (e: React.MouseEvent<SVGSVGElement>) => {
    e.stopPropagation();
    if (audioRef.current) {
      audioRef.current.pause();
      audioRef.current.currentTime = 0;
      setIsPlaying(false);
    }
  };

  const handleAudioEnded = () => {
    setIsPlaying(false);
  };

  const accentMap = useMemo(() => {
    // Exclude field to show
    const excludeField = [
      '_id',
      '__v',
      'updatedAt',
      'createdAt',
      'accentIndexId',
    ];
    const accents = wordDetail?.accents?.[0] as any;
    if (wordDetail?.accents?.[0]) {
      const dataAccent = Object.keys(wordDetail.accents?.[0])
        .filter((item) => excludeField.every((field) => field !== item))
        .map((item) => ({ [item]: accents[item] }));
      return dataAccent;
    } else {
      return [];
    }
  }, [wordDetail]);

  if (loadingWord) {
    return <WordDetailSkeleton />;
  }

  if (isError) {
    return <div>Error loading word details</div>;
  }

  if (!wordDetail) {
    return <div>No word details found</div>;
  }

  return (
    <Layout>
      <HokkienDictionaryHeader />
      <Content>
        <div style={{ maxWidth: 1100, margin: '0 auto', padding: 24 }}>
          {/* Summary Detail Word */}
          <Row gutter={[16, 16]}>
            <Col xs={24} md={16}>
              <Flex vertical>
                <Space size={'large'} style={{ alignItems: 'center' }}>
                  <Text
                    label={wordDetail?.hanji}
                    fontSize={24}
                    fontWeight={600}
                    style={{ color: '#667085' }}
                  />

                  {wordDetail?.wordIndexId && (
                    <Text
                      label={`${t('indexId')}: ${wordDetail.wordIndexId}`}
                      fontSize={14}
                      style={{ color: '#98A2B3' }}
                    />
                  )}

                  {/* Voice Player */}
                  {isLoading ? (
                    <LoadingOutlined
                      style={{ color: '#7E5435', fontSize: 24 }}
                    />
                  ) : (
                    <Tooltip
                      title={
                        !wordDetail?.recordURL
                          ? t('audioNotFound')
                          : !validAudioURL
                            ? t('invalidAudioSource')
                            : isPlaying
                              ? t('stop')
                              : t('play')
                      }
                    >
                      <span
                        onClick={handlePlayPauseAudio}
                        style={{
                          cursor: !wordDetail?.recordURL
                            ? 'not-allowed'
                            : 'pointer',
                        }}
                      >
                        {isPlaying ? (
                          <StopIcon
                            style={{
                              color: '#7E5435',
                              fontSize: 20,
                              cursor: 'pointer',
                            }}
                            onClick={handleStopAudio}
                          />
                        ) : !validAudioURL ? (
                          <SoundFilled
                            style={{ color: '#d9d9d9', fontSize: 24 }}
                          />
                        ) : (
                          <SoundOutlined
                            style={{ color: '#7E5435', fontSize: 24 }}
                          />
                        )}
                      </span>
                    </Tooltip>
                  )}
                  <audio
                    ref={audioRef}
                    onEnded={handleAudioEnded}
                    onError={(e) => setValidAudioURL(false)}
                    controls
                    style={{ display: 'none' }}
                  >
                    <source
                      src={wordDetail?.recordURL}
                      type={wordDetail?.recordType}
                    />
                    Your browser does not support the audio element.
                  </audio>
                  {/* End Voice Player */}
                </Space>
                <Text label={wordDetail?.roman} style={{ color: '#667085' }} />
              </Flex>
              <div style={{ height: 16 }} />
              <Flex vertical>
                {wordAlternateVariantMap && (
                  <Row>
                    <Col xs={24} md={{ flex: '160px' }}>
                      <Text
                        label={`${t('wordVariantCharacter')} `}
                        fontSize={14}
                        fontWeight={300}
                        style={{
                          color: '#98A2B3',
                        }}
                      />
                    </Col>
                    <Col xs={0} md={1}>
                      <Text
                        label={`: `}
                        fontSize={14}
                        fontWeight={300}
                        style={{
                          color: '#98A2B3',
                        }}
                      />
                    </Col>
                    <Col xs={24} md={12}>
                      <Text
                        label={wordAlternateVariantMap ?? '-'}
                        fontSize={14}
                      />
                    </Col>
                  </Row>
                )}
                {wordPronunciationMap && (
                  <Row>
                    <Col xs={24} md={{ flex: '160px' }}>
                      <Text
                        label={`${t('pronunciation')} `}
                        fontSize={14}
                        fontWeight={300}
                        style={{
                          color: '#98A2B3',
                        }}
                      />
                    </Col>
                    <Col xs={0} md={1}>
                      <Text label={':'} fontSize={14} />
                    </Col>
                    <Col xs={24} md={12}>
                      <Text label={wordPronunciationMap ?? '-'} fontSize={14} />
                    </Col>
                  </Row>
                )}
                {wordPronunciationMixWordMap && (
                  <Row>
                    <Col xs={24} md={{ flex: '160px' }}>
                      <Text
                        label={`${t('mixWord')} `}
                        fontSize={14}
                        fontWeight={300}
                        style={{
                          color: '#98A2B3',
                        }}
                      />
                    </Col>
                    <Col xs={0} md={1}>
                      <Text label={':'} fontSize={14} />
                    </Col>
                    <Col xs={24} md={12}>
                      <Text
                        label={wordPronunciationMixWordMap ?? '-'}
                        fontSize={14}
                      />
                    </Col>
                  </Row>
                )}
                {wordPronunciationCorruptedMap && (
                  <Row>
                    <Col xs={24} md={{ flex: '160px' }}>
                      <Text
                        label={`${t('commonlyPronounced')} `}
                        fontSize={14}
                        fontWeight={300}
                        style={{
                          color: '#98A2B3',
                        }}
                      />
                    </Col>
                    <Col xs={0} md={1}>
                      <Text label={':'} fontSize={14} />
                    </Col>
                    <Col xs={24} md={12}>
                      <Text
                        label={wordPronunciationCorruptedMap ?? '-'}
                        fontSize={14}
                      />
                    </Col>
                  </Row>
                )}
              </Flex>
            </Col>
            <Col xs={24} md={8}>
              <Flex vertical wrap='wrap'>
                <Text
                  label={`${t('source')}: ${wordDetail?.dictionary?.name ?? '-'}`}
                />
              </Flex>
            </Col>
          </Row>
          {wordDetail?.wordSynonyms?.length > 0 && (
            <WordGroup
              title={t('wordSynonym')}
              items={wordDetail.wordSynonyms}
            />
          )}
          {wordDetail?.wordAntonyms?.length > 0 && (
            <WordGroup
              title={t('wordAntonym')}
              items={wordDetail.wordAntonyms}
            />
          )}

          {/* End Summary Word Detail */}
          <div style={{ height: 24 }} />
          {wordDetail?.meanings?.map((meaning, meaningIndex) => (
            <HokkienExample
              meaningIndex={meaningIndex}
              key={meaningIndex}
              data={{ ...meaning, title: wordDetail.hanji }}
            />
          ))}

          <CommentSection wordId={wordId || ''} />
        </div>
      </Content>
      <HokkienDictionaryFooter />
    </Layout>
  );
};

export default WordDetailComponent;
