import { useContext } from 'react';
import axiosInstance from '../../../common/axiosInstance';
import { ProfileType } from '../type/ProfileType';
import { AuthContext } from '../../../context/AuthContext';

export const useProfileAPI = () => {
  const authContext = useContext(AuthContext);

  if (!authContext) {
    throw new Error('useProfileAPI must be used within an AuthProvider');
  }

  const fetchProfile = async (): Promise<ProfileType> => {
    if (!authContext.isAuthenticated()) {
      throw new Error('User is not authenticated');
    }

    const userId = authContext.user?.user?.id;
    if (!userId) {
      throw new Error('User ID is not available');
    }

    const { data } = await axiosInstance.get(`/users/${userId}`);
    return {
      id: data.id,
      username: data.username,
      email: data.email,
      password: data.password,
      role: data.role,
      profilePicture:
        data.imageURL ||
        'https://zos.alipayobjects.com/rmsportal/ODTLcjxAfvqbxHnVXCYX.png',
      isShowLeaderBoard: data.isShowLeaderBoard || false,
    };
  };

  const updateProfile = async (
    updatedProfile: FormData
  ): Promise<ProfileType> => {
    if (!authContext.isAuthenticated()) {
      throw new Error('User is not authenticated');
    }

    const userId = authContext.user?.user?.id;
    if (!userId) {
      throw new Error('User ID is not available');
    }

    const { data } = await axiosInstance.patch(
      `/users/${userId}`,
      updatedProfile,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      }
    );
    return data;
  };

  return { fetchProfile, updateProfile };
};
