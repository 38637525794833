import { Typography } from 'antd';
import React from 'react';
import { ITextProps } from './types';

const Text = (props: ITextProps) => {
  return (
    <Typography.Text
      {...props}
      style={{
        ...props.style,
        fontSize: `${props.fontSize}` ?? '16px',
        fontWeight: props.fontWeight ?? 400,
      }}
    >
      {props.label}
    </Typography.Text>
  );
};

export default Text;
