import Cookies from 'js-cookie';
import axiosInstance from '../../../common/axiosInstance';
import { CommentType } from '../type/CommentType';

export const fetchComments = async (wordId: string): Promise<CommentType[]> => {
  try {
    const userLogged = Cookies.get('user')
      ? JSON.parse(Cookies.get('user') ?? '')
      : '';
    const userId = userLogged?.user?._id || userLogged?.user?.id;

    if (userId) {
      const { data } = await axiosInstance.get(
        `/comments/public/word/${wordId}?userId=${userId}`
      );
      return data;
    } else {
      const { data } = await axiosInstance.get(
        `/comments/public/word/${wordId}`
      );
      return data;
    }
  } catch (err) {
    return err as any;
  }
};

export const postComment = async (
  userId: string,
  wordId: string,
  commentText: string
): Promise<CommentType> => {
  const { data } = await axiosInstance.post('/comments', {
    userId: userId,
    wordId: wordId,
    comment: commentText,
  });
  return data;
};
