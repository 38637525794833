import { useQuery } from '@tanstack/react-query';
import React, { useContext, useMemo } from 'react';
import { Row, Col, Alert, Skeleton, Empty } from 'antd';
import { fetchRandomWords } from '../api/HomeAPI';
import HokkienWordBox from '../../../components/ui/HokkienWordBox';
import { useTranslation } from 'react-i18next';
import {
  RandomWord,
  RandomWordsApiResponse,
  RandomWordsResponse,
} from '../types/HomeType';
import { IWordListsResponse } from '../../../types/word';
import { useNavigate } from 'react-router-dom';
import { LikeContext } from '../../../context/LikeContext';
import { fetchLikesByUser } from '../../SearchResults/api/SearchAPI';
import { AuthContext } from '../../../context/AuthContext';

const RandomWordsComponent: React.FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  // Context
  const likeContext = useContext(LikeContext);
  const authContext = useContext(AuthContext);

  const setInitialLikes = likeContext?.setInitialLikes;
  const user =
    authContext?.user || JSON.parse(localStorage.getItem('user') || 'null');

  const {
    data: wordLists,
    error,
    isLoading,
  } = useQuery<RandomWordsApiResponse, Error>({
    queryKey: ['randomWords'],
    queryFn: fetchRandomWords,
    refetchOnWindowFocus: false,
  });

  const {
    data: likeLists,
    isLoading: loadingLike,
    isFetching: fetchingLike,
  } = useQuery({
    queryKey: ['like'],
    queryFn: () => fetchLikesByUser(user?.user?.username),
  });

  const dataWordListsMap = useMemo((): RandomWordsResponse['results'] => {
    if (wordLists?.[0]?.results) {
      const dataLikes = likeLists?.results || [];
      const dataWords = wordLists?.[0]?.results;

      const mapingWordWithLikesByUser = dataWords.map((word) => {
        const matchingWordLike = dataLikes.some(
          (like) => like.word._id === word._id && like.isLike
        );
        const matchingWordDislike = dataLikes.some(
          (like) => like.word._id === word._id && !like.isLike
        );
        if (matchingWordLike) {
          return {
            ...word,
            isLike: true,
          };
        } else if (matchingWordDislike) {
          return { ...word, isLike: false };
        } else {
          return word;
        }
      });
      setInitialLikes!(mapingWordWithLikesByUser);
      return mapingWordWithLikesByUser;
    } else {
      return [];
    }
  }, [wordLists, likeLists]);

  const randomWords = useMemo((): IWordListsResponse[] => {
    const words = wordLists?.[0]?.results || [];

    const newWords = words.map((item) => ({
      ...item,
      randomWordId: wordLists?.[0].id,
    }));
    return newWords;
  }, [wordLists]);

  if (isLoading) {
    return (
      <Row gutter={[24, 24]}>
        {[...Array(6)].map((_, index) => (
          <Col xs={24} sm={12} md={8} key={index}>
            <Skeleton active paragraph={{ rows: 4 }} />
          </Col>
        ))}
      </Row>
    );
  }

  if (error) {
    return <Alert message='Error' description={error.message} type='error' />;
  }

  if (randomWords.length === 0) {
    console.log('No random words', wordLists);
    return <Empty description={t('noRandomWords')} />;
  }

  return (
    <Row gutter={[24, 24]}>
      {randomWords.slice(0, 6).map((word: IWordListsResponse) => (
        <Col
          xs={24}
          sm={12}
          md={8}
          key={word._id}
          onClick={() => navigate(`/search/word/${word.wordId}`)}
        >
          <HokkienWordBox data={word} loading={false} />
        </Col>
      ))}
    </Row>
  );
};

export default RandomWordsComponent;
