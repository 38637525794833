export const languageOptions = [
  {
    value: 'nan',
    label: '福建話',
  },
  {
    value: 'en',
    label: 'English',
  },
  {
    value: 'zh',
    label: '華語',
  },
];
