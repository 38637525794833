import axiosInstance from '../../../common/axiosInstance';
import { IGeneralResponse } from '../../../types/GenerateTypes';
import { ILikesResponse } from '../../../types/likes';
import { IWordListsResponse } from '../../../types/word';

export const fetchWordBySearch = async (
  page: number = 1,
  limit: number = 10,
  query: string
): Promise<IGeneralResponse<IWordListsResponse[]>> => {
  try {
    const response = await axiosInstance.get<
      IGeneralResponse<IWordListsResponse[]>
    >(`/vocabs/public?page=${page}&limit=${limit}&${query}`);
    return response.data;
  } catch (err) {
    console.log('Error searching word: ', err);
    throw err;
  }
};

export const fetchLikesByUser = async (
  name: string
): Promise<IGeneralResponse<ILikesResponse[]>> => {
  try {
    const response = await axiosInstance.get<
      IGeneralResponse<ILikesResponse[]>
    >(`/likes?page=1&limit=9999&username=${name}`);
    return response.data;
  } catch (error) {
    console.log('Error fetch likes: ', error);
    throw error;
  }
};
