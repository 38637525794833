/**
 * @description Check wording is chinese
 * @param string
 * @returns boolean
 */

export const isChinese = (string: string): boolean => {
  const checkingChar = /\p{Script=Han}/u.test(string);
  if (checkingChar) {
    return true;
  } else {
    return false;
  }
};
